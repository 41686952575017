import React from "react";
import Select from "../../util/Select";
import { PropTypes } from "prop-types";
import Input from "../../util/Input";
import {Toggle} from "../../util/Toggle";
const RWAMappingInfo = props => {
    return (
        <div className="AppSection">

            <div className="leftColumn margin-height">
                <Select
                    labelText={"RWA Group"}
                    className={"margin-height lblNVGreen required"}
                    inputClassName={"selectWidth2"}
                    optionValues={props.rwaGroups}
                    isRequired={true}
                    value={props.orgData.RWAGroupID}
                    onChange={props.handleRWAGroupChange}
                    displayFieldName="value"
                    valueFieldName="key"
                />
                <Select
                    labelText={"License Type"}
                    className={"margin-height lblNVGreen required"}
                    inputClassName={"selectWidth2"}
                    optionValues={props.licTypes}
                    isRequired={true}
                    value={props.orgData.LicenseTypeID}
                    onChange={props.handleLicenseTypeChange}
                    displayFieldName="value"
                    valueFieldName="key"
                />

                <Select
                    labelText={"Product Family"}
                    className={"margin-height lblNVGreen required"}
                    inputClassName={"selectWidth"}
                    optionValues={props.familyTypes}
                    isRequired={true}
                    value={props.orgData.ProductFamilyID}
                    onChange={props.handleProductFamilyChange}
                    displayFieldName="value"
                    valueFieldName="key"
                />
               
                <Select
                    labelText={"IDM Group"}
                    className={"margin-height lblNVGreen required"}
                    inputClassName={"selectWidth2"}
                    optionValues={props.idmGroups}
                    isRequired={true}
                    value={props.orgData.IDMGroupID}
                    onChange={props.handleIDMGroupChange}
                    displayFieldName="value"
                    valueFieldName="key"
                />
                  <Input
                    labelText={"NGCLicense"}
                    className={"margin-height lblNVGreen required"}
                    inputClassName={"margin-leftRWAMapping"}
                    isRequired={true}
                    autoFocus={props.autoFocusName}
                    id={"aName"}
                    value={props.orgData.NGCLicenseTypeName}
                    onChange={props.handleNGCLicenseTypeNameChange}
                />
                  <div className="row" style={{ marginTop: '10px' }}>
                    <div >
                        <label className="margin-height lblNVGreen  ">Import Product Bom:</label>
                    </div>
                    <div style={{ display: 'inline-block', position: 'fixed', left: '53%' }}>
                        <Toggle
                                
                            selected={props.orgData.ImportProductBom}
                           toggleSelected={props.handleBOMUpdateToggleChange}
                        />
                    </div>
                </div>
                <div className="flex-fill margin-height">
                    <center><button className="btn" onClick={props.onSaveClick} disabled={!props.dataFilled} > Save  </button> </center>
                </div>
            </div>
        </div>
     );
};

RWAMappingInfo.propTypes = {
    orgData: PropTypes.object,
    onSaveClick: PropTypes.func,
    onCancelClick: PropTypes.func,
    dataFilled: PropTypes.bool
};


export default RWAMappingInfo;
