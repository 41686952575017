import "@babel/polyfill";
import "react-app-polyfill/ie11";
import { runWithAdal } from "react-adal";
import { authContext } from "./adalConfig";

const DO_NOT_LOGIN = false;
runWithAdal(
  authContext,
  () => {
    // eslint-disable-next-line
    require("./indexApp.js");
  },
  DO_NOT_LOGIN
);
// import React from "react";
// import ReactDOM from "react-dom";
// import App from "./App";
// import * as serviceWorker from "./serviceWorker";

// ReactDOM.render(<App />, document.getElementById("root"));

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
