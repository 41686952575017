import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import formatDate from "./util/Date";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as api from "./constants/api";
import { adalApiFetch } from "./adalConfig";
import ReactTable from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-table/react-table.css";
import { Button } from "@material-ui/core";

import NavMenu from "../src/components/NavMenu";

import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import { DefinedRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";

import Moment from "moment";
import RegListDateFilter from "./components/RegDetailsView/RegListDateFlter";
import AuditTrailPopUp from "./components/auditTrail";
import { toastError } from "../src/util/CommonFunctions";
import {json2excel} from 'js2excel';

// import MomentLocaleUtils, { FormatDate, parseDate } from "moment";

class RegistrationList extends Component {
  constructor(props) {
    super(props);

    this.onUnload = this.onUnload.bind(this);
    var dateObj = new Date();
    dateObj.setDate(1);

    this.state = {
      GetMoreEnabled: false,
      defaultOption: { key: 0, value: "All" },
      Registrations: [],
      DisplayedCount: 0,
      Count: 0,
      Take: 10,
      ShowFilters: true,
      Filters: {
        LicenseTypeValue: "",
        FamilyTypeValue: "",
        StatusValue: props.statusFilter ? props.statusFilter : "",
        StatusEnabled: props.statusFilter ? false : true,
        StatusDisplay: props.statusDisplay ? props.statusDisplay : "",
        EmailValue: "",
        AccountName: "",
        ClaimTypeValue: "",
        EntitlementValue: "",
        DateFromValue: dateObj.toLocaleString(),
        DateToValue: new Date().toLocaleString(),
      },
      RetainState: {
        SortedBy: "id",
        SortOrder_Desc: true,
        PageNo: 0,
      },
      Filter: [],

      LicenseTypes: [],
      FamilyTypes: [],
      Statuses: [],
      ClaimTypes: [],
      StartDate: null,
      EndDate: null,
      IsLoaded: false,
      IsFiltering: false,
      StartDateFilter: null,
      EndDateFilter: null,
      filterSelected: "This Month",
    };
    this.dateFilterClicked = this.dateFilterClicked.bind(this);
    this.handleSelectDefinedRange = this.handleSelectDefinedRange.bind(this);
    this.getReportData=this.getReportData.bind(this);
  }
  componentDidMount() {
    this.getLookups();
    this.getInitial(true);

    window.addEventListener("beforeunload", this.onUnload);
    var flter = JSON.parse(sessionStorage.getItem("Filter"));
    var pg = parseInt(sessionStorage.getItem("page"));

    var sortedBy = sessionStorage.getItem("SortedBy");
    var sortOrder_Desc = sessionStorage.getItem("SortOrderDesc");

    var pageSize = parseInt(sessionStorage.getItem("PgSz"));

    if (sortedBy) {
      let rs = this.state.RetainState;
      rs.SortedBy = sortedBy;
      this.setState({ RetainState: rs });
    }
    if (sortOrder_Desc) {
      var isTrueSet = sortOrder_Desc.toLowerCase() === "true";
      let rs = this.state.RetainState;
      rs.SortOrder_Desc = isTrueSet;
      this.setState({ RetainState: rs });
    }

    if (pageSize) {
      this.setState({ Take: parseInt(pageSize) });
    }

    if (flter) {
      var hasDateFilter = this.getFilterDate(flter);
      if (hasDateFilter) {
        this.setState({ StartDate: new Date(hasDateFilter) });
      }
      this.setState({ Filter: flter });
    }

    if (pg) {
      let rs = this.state.RetainState;
      rs.PageNo = parseInt(pg);
      this.setState({ RetainState: rs });
    }
  }

  getFilterDate(flter) {
    for (let i = 0; i < flter.length; i++) {
      if (flter[i]["id"] === "creationTime") {
        return flter[i]["value"];
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    var pg = parseInt(sessionStorage.getItem("page"));
    var pageCount = parseInt(sessionStorage.getItem("PgCnt"));
    if (pg > pageCount) {
      let rs = this.state.RetainState;
      rs.PageNo = pageCount - 1 === -1 ? 0 : pageCount - 1;
      this.setState({ RetainState: rs });
    }
    sessionStorage.setItem("Filter", JSON.stringify(this.state.Filter));
    sessionStorage.setItem("page", parseInt(this.state.RetainState.PageNo));
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onUnload);
  }

  onUnload(event) {
    sessionStorage.clear();
  }
  handleSelectDefinedRange(ranges) {
    this.setState({
      Filter: [],
      StartDateFilter: null,
      EndDateFilter: null,
      StartDate: null,
      EndDate: null,
    });

    // add a day

    this.setState({
      Filter: [
        { id: "creationTimeFrom", value: ranges["selection"].startDate },
        {
          id: "creationTimeTo",
          value: ranges["selection"].endDate.setDate(
            ranges["selection"].endDate.getDate() + 1
          ),
        },
      ],
      StartDate: null,
      EndDate: null,
    });
    // console.log(
    //   ranges["selection"].endDate.setDate(
    //     ranges["selection"].endDate.getDate() + 1
    //   )
    // );
  }

  handleChangeStartDateFilter = (value, accessor) => {
    let filtered = this.state.Filter;
    let insertNewFilter = 1;
    if (filtered.length) {
      filtered.forEach((filter, i) => {
        if (filter["id"] === accessor) {
          if (value === "" || !value) {
            filtered.splice(i, 1);
          } else {
            filter["value"] = value;
          }
          insertNewFilter = 0;
        }
      });
    }
    if (insertNewFilter) {
      filtered.push({ id: accessor, value: value });
    }

    this.setState({ Filter: filtered });
  };
  dateFilterClicked(filterName, value = "") {
    let filtsel = "None";
    let f = this.state.Filters;
    let dateObj = new Date();
    switch (filterName) {
      case "Today":
        if(this.state.Filter){
               
            f.EmailValue='';                     
            f.EntitlementValue='';    
            f.AccountName='';          
            f.id='';
          
        }
        f.DateFromValue = new Date().toLocaleString();
        f.DateToValue = new Date().toLocaleString();
        this.setState({
          Filter: [],
          Filters: f,
          IsLoaded: false,
          filterSelected: "Today",
          StartDateFilter: null,
          EndDateFilter: null,
          StartDate: null,
        });
        this.getInitial(true);
        break;
      case "This Week":
        if(this.state.Filter){
               
          f.EmailValue='';                     
          f.EntitlementValue='';    
          f.AccountName='';          
          f.id='';
        
      }
        f.DateFromValue = Moment().startOf("isoweek").toDate();
        f.DateToValue = Moment().endOf("isoweek").toDate();
        this.setState({
          Filter: [],
          Filters: f,
          IsLoaded: false,
          filterSelected: "This Week",
          StartDateFilter: null,
          EndDateFilter: null,
          StartDate: null,
        });
        this.getInitial(true);
        break;
      case "This Month":
        if(this.state.Filter){
               
          f.EmailValue='';                     
          f.EntitlementValue='';    
          f.AccountName='';          
          f.id='';
        
      }
        f.DateFromValue = Moment().startOf("month").toDate();
        f.DateToValue = Moment().endOf("month").toDate();
        console.log("fromdate" + f.DateFromValue);
        console.log("Todate" + f.DateToValue);

        this.setState({
          Filter: [],
          Filters: f,
          IsLoaded: false,
          filterSelected: "This Month",
          StartDateFilter: null,
          EndDateFilter: null,
          StartDate: null,
        });
        this.getInitial(true);

        break;
      case "All":
       
        
        let d = new Date();
        d.setFullYear(2018);
       
        f.DateFromValue = d.toLocaleString();
        f.DateToValue = new Date().toLocaleString();
        this.setState({
          Filter: [],
          Filters: f,
          IsLoaded: false,
          filterSelected: "All",
          StartDateFilter: null,
          EndDateFilter: null,
          StartDate: null,
        });
        this.getInitial(true,true);
        break;

      case "FromDate":
        if(this.state.Filter){
               
          f.EmailValue='';                     
          f.EntitlementValue='';    
          f.AccountName='';          
          f.id='';
        
      }
        if (value) {
          f.DateFromValue = value.toLocaleString();
        } else {
          let d = new Date();
          d.setFullYear(2018);
          f.DateFromValue = d.toLocaleString();
        }
        if (this.state.EndDateFilter) {
          f.DateToValue = this.state.EndDateFilter;
        } else {
          f.DateToValue = new Date().toLocaleString();
        }
        if (!value && !this.state.EndDateFilter) {
          f.DateFromValue = Moment().startOf("month").toDate();
          f.DateToValue = Moment().endOf("month").toDate();
          filtsel = "This Month";
        }
        this.setState({
          Filter: [],
          Filters: f,
          IsLoaded: false,
          filterSelected: filtsel,
          StartDateFilter: value,
          StartDate: null,
        });
        this.getInitial(true);
        break;
      case "ToDate":
        if(this.state.Filter){
               
          f.EmailValue='';                     
          f.EntitlementValue='';    
          f.AccountName='';          
          f.id='';
        
      }
        if (value) {
          f.DateToValue = value.toLocaleString();
        } else {
          f.DateToValue = new Date().toLocaleString();
        }
        if (this.state.StartDateFilter) {
          f.DateFromValue = this.state.StartDateFilter;
        } else {
          let d = new Date();
          d.setFullYear(2018);
          f.DateFromValue = d.toLocaleString();
        }
        if (!value && !this.state.StartDateFilter) {
          f.DateFromValue = Moment().startOf("month").toDate();
          f.DateToValue = Moment().endOf("month").toDate();
          filtsel = "This Month";
        }
        this.setState({
          Filter: [],
          Filters: f,
          IsLoaded: false,
          filterSelected: filtsel,
          EndDateFilter: value,
          StartDate: null,
        });
        this.getInitial(true);
        break;
      default:
        break;
    }

    // this.setState({  Filters: f });
  }
  render() {
    const {
      Registrations: data,
      LicenseTypes,
      FamilyTypes,
      Statuses,
      IsLoaded,
    } = this.state;

    const { canViewRWAMapping } = this.props;

    // const selectStatus = Statuses.map(s => {
    //   return { value: s.key, label: s.value };
    // });

    const licTypes = LicenseTypes.map((o) => {
      return (
        <option key={o.key} value={o.key}>
          {o.value}
        </option>
      );
    });
    const familyTypes = FamilyTypes.map((o) => {
      return (
        <option key={o.key} value={o.key}>
          {o.value}
        </option>
      );
    });
    const statuses = Statuses.map((o) => {
      return (
        <option key={o.key} value={o.key}>
          {o.value}
        </option>
      );
    });

    const columns = [
      {
        Header: "ID",
        accessor: "id",
        maxWidth: 100,
      },
      {
        Header: "Entitlement",
        accessor: "entitlement",
        width: 290,
        maxWidth: 290,
        filterMethod: this.defaultFilter,
      },
      {
        Header: "Type",
        accessor: "licenseTypeName",
        maxWidth: 130,
        Filter: ({ filter, onChange }) => (
          <select
            onChange={(event) => {
              onChange(event.target.value);
            }}
            style={{ width: "100%" }}
            value={filter ? filter.value : "All"}
          >
            {licTypes}
          </select>
        ),
        filterMethod: (filter, row) => {
          if (parseInt(filter.value) === 0) {
            return row;
          } else {
            return row["_original"].licenseTypeId === parseInt(filter.value);
          }
        },
      },
      {
        Header: "Family",
        accessor: "productFamilyName",
        maxWidth: 75,
        Filter: ({ filter, onChange }) => (
          <select
            onChange={(event) => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "All"}
          >
            {familyTypes}
          </select>
        ),
        filterMethod: (filter, row) => {
          if (parseInt(filter.value) === 0) {
            return true;
          } else {
            return row["_original"].productFamilyId === parseInt(filter.value);
          }
        },
      },
      {
        Header: "Email",
        maxWidth: 270,
        accessor: "emailID",
        filterMethod: this.defaultFilter,
      },
      {
        Header: "Account Name",
        accessor: "companyDetail.companyName",
        filterMethod: this.defaultFilter,
      },
      {
        accessor: "requestStatusId",
        show: false,
        Header: "rsid",
      },
      {
        Header: "Status",
        accessor: "requestStatusName",
        maxWidth: 120,
        Filter: ({ filter, onChange }) => (
          <select
            onChange={(event) => {
              this.setState({ IsFiltering: true });
              onChange(event.target.value);

              let f = this.state.Filters;
              f.StatusValue = "";
              // this.setState({  Filters: f });
              this.setState({ Filter: [], Filters: f });
            }}
            style={{ width: "100%" }}
            value={filter ? filter.value : "All"}
          >
            {statuses}
          </select>
        ),
        filterMethod: (filter, row) => {
          //this.setState({ IsFiltering: true });
          //default filter method passes a single row of data
          if (parseInt(filter.value) === 0) {
            return true;
          } else if (parseInt(filter.value) === -1) {
            //show p* status'
            return row.requestStatusId !== 7 && row.requestStatusId !== 8;
          } else {
            return row.requestStatusId === parseInt(filter.value);
          }

          //return data;
          //filter all returns the entire array of data
          //console.log(data);
          // if (parseInt(filter.value) === 0) {
          //   return data;
          // } else if (parseInt(filter.value) === -1) {
          //   //show p* status'
          //   return data.filter(
          //     d => d.requestStatusId !== 7 && d.requestStatusId !== 8
          //   );
          // } else {
          //   return data.filter(
          //     d => d.requestStatusId === parseInt(filter.value)
          //   );
          // }
        },
      },
      {
        Header: "Date",
        accessor: "creationTime",
        width: 150,
        maxWidth: 150,
        Filter: ({ filter, onChange }) => (
          <DatePicker
            isClearable
            selected={this.state.StartDate}
            onChange={(date) => {
              this.setState({ StartDate: date });

              if (date !== null) {
                let d = `${
                  date.getMonth() + 1
                }/${date.getDate()}/${date.getFullYear()}`;
                onChange(d);
              } else {
                onChange("");
              }
            }}
            popperModifiers={{
              offset: {
                enabled: true,
                offset: "-40px, 0px",
              },
              preventOverflow: {
                enabled: true,
                escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                boundariesElement: "viewport",
              },
            }}
          />
        ),
      },
      {
        Header: "Date",
        accessor: "creationTimeFrom",
        maxWidth: 150,
        show: false,
        Filter: ({ filter, onChange }) => (
          <DatePicker
            isClearable
            selected={this.state.StartDateFilter}
            onChange={(entry) => {
              this.handleChangeStartDateFilter(entry, "creationTimeFrom");
            }}
            popperModifiers={{
              offset: {
                enabled: true,
                offset: "-40px, 0px",
              },
              preventOverflow: {
                enabled: true,
                escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                boundariesElement: "viewport",
              },
            }}
          />
        ),
        filterMethod: (filter, row) => {
          let endValue = this.state.EndDateFilter
            ? this.state.EndDateFilter
            : Moment();

          let startValue = filter.value;

          let checkvalue = row[filter.id];
          if (!startValue) {
            return true;
          }
          // !(x.isBefore(a) || x.isAfter(b))
          if (
            Moment(checkvalue).isBetween(startValue, endValue) ||
            Moment(checkvalue).isSame(startValue) ||
            Moment(checkvalue).isSame(endValue)
          ) {
            return true;
          } else {
            return false;
          }
          // if (Moment(checkvalue).isBetween(startValue, endValue, null, "[]")) {
          //   return true;
          // } else {
          //   return false;
          // }
        },
      },
      {
        Header: "Date",
        accessor: "creationTimeTo",
        maxWidth: 150,
        show: false,
        Filter: ({ filter, onChange }) => (
          <DatePicker
            isClearable
            selected={this.state.EndDateFilter}
            onChange={(entry) => {
              this.handleChangeStartDateFilter(entry, "creationTimeTo");
            }}
            popperModifiers={{
              offset: {
                enabled: true,
                offset: "-40px, 0px",
              },
              preventOverflow: {
                enabled: true,
                escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                boundariesElement: "viewport",
              },
            }}
          />
        ),
        filterMethod: (filter, row) => {
          let startValue = this.state.StartDateFilter
            ? this.state.StartDateFilter
            : Moment("2019-01-01");

          let endValue = filter.value;

          let checkvalue = row[filter.id];
          if (!endValue) {
            return true;
          }
          if (
            Moment(checkvalue).isBetween(startValue, endValue) ||
            Moment(checkvalue).isSame(startValue) ||
            Moment(checkvalue).isSame(endValue)
          ) {
            return true;
          } else {
            return false;
          }
        },
      },
      {
        Header: "Action",
        style: { flex: "1" },
        width: 100,
        minWidth: 100,
        maxWidth: 100,
        filterable: true,
        sortable: false,
        Filter: ({ filter, onChange }) => (
          <div className='flex'>
            <button
              className='btn small'
              title='Clear filters'
              onClick={() => {
                let f = this.state.Filters;
                f.StatusValue = "";
                let rs = this.state.Filters;

                rs.SortedBy = "id";
                rs.SortOrder_Desc = true;
                rs.PageNo = 0;

                this.setState({
                  Filter: [],
                  Filters: f,
                  RetainState: rs,
                  StartDate: null,
                  EndDate: null,
                  StartDateFilter: null,
                  EndDateFilter: null,
                });
              }}
            >
              <FontAwesomeIcon icon='undo' color='Grey' />
            </button>
            <button
              className='btn small'
              title='Refresh'
              onClick={() => {
                this.getInitial();
              }}
            >
              <FontAwesomeIcon icon='sync' color='Grey' />
            </button>
          </div>
        ),
        Cell: (props) => {
          const showMatchState = props.original.requestStatusId === 2;
          return (
            <div className='flex'>
              <Link
                className='auditTrailSty'
                to={{
                  pathname: "/detail/" + props.original.id,
                  state: { assignView: showMatchState },
                }}
              >
                <button
                  className='btn small auditBtn'
                  onClick={() => this.setState({ Filter: this.state.Filter })}
                >
                  {showMatchState ? "Match" : "View"}
                </button>
              </Link>
              <AuditTrailPopUp regID={props.original.id}></AuditTrailPopUp>
            </div>
          );
        },
      },
    ];

    if (!IsLoaded) {
      return (
        <div className='loading-full'>
          <FontAwesomeIcon
            icon='spinner'
            spin
            className='color-nvgreen'
            size='2x'
          />
          <div className='loading-text'>loading page content</div>
        </div>
      );
    }
    const selectionRange = {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    };
    return (
      <div className='content'>
        {canViewRWAMapping && <NavMenu />}
        <ReactTable
          data={data}
          columns={columns}
          defaultPageSize={this.state.Take}
          minRows={0}
          filterable={this.state.ShowFilters}
          resizable={false}
          className='-striped -highlight'
          filtered={this.state.Filter}
          page={this.state.RetainState.PageNo}
          onPageChange={(page) => {
            let rs = this.state.RetainState;
            rs.PageNo = page;
            this.setState({ RetainState: rs });
          }}
          onPageSizeChange={(page) => {
            var pageCount = parseInt(sessionStorage.getItem("PgCnt"));
            var currPage = parseInt(sessionStorage.getItem("page"));
            if (currPage > pageCount) {
              let rs = this.state.RetainState;
              rs.PageNo = pageCount - 1;
              this.setState({ RetainState: rs });
            } else {
              let rs = this.state.RetainState;
              rs.PageNo = 0;
              this.setState({ RetainState: rs });
            }
          }}
          defaultSorted={[
            {
              id: this.state.RetainState.SortedBy,
              desc: this.state.RetainState.SortOrder_Desc,
            },
          ]}
          onSortedChange={(sortProperties) => {
            sessionStorage.setItem("SortedBy", sortProperties[0]["id"]);
            sessionStorage.setItem("SortOrderDesc", sortProperties[0]["desc"]);
          }}
          onFilteredChange={(filtered, column, value) => {
            this.setState({ Filter: filtered });
            // this.handleChangeStartDateFilter(
            //   value,
            //   column.id || column.accessor
            // );
          }}
          // onFilteredChange={filtered => {
          //   this.setState({ Filter: filtered });
          // }}
          getPaginationProps={(a) => {
            sessionStorage.setItem("PgCnt", parseInt(a.pages));
            sessionStorage.setItem("PgSz", parseInt(a.pageSize));
            return {};
          }}
        >
          {(state, makeTable, instance) => {
            let recordsInfoText = "";
            const errorCount = data.filter(
              (e) => e.requestStatusName === "ERROR"
            ).length;
            const incompleteCount = data.filter(
              (e) =>
                e.requestStatusName !== "COMPL" &&
                e.requestStatusName !== "ERROR"
            ).length;
            const cancelledCount = data.filter(
              (e) => e.requestStatusName === "CANCEL"
            ).length;
            const errorButton = (
              <button
                className='error'
                onClick={() => {
                  this.setState({
                    Filter: [{ id: "requestStatusName", value: "7" }],
                    StartDate: null,
                    EndDate: null,
                  });
                }}
              >
                <div className='text'>Errors</div>
                <div className='badge'>
                  <div>{errorCount}</div>
                </div>
              </button>
            );
            // const notComplIds = Statuses.filter(s => {
            //   return s.value !== "COMPL" && s.value !== "ERROR" && s.key !== 0;
            // })
            //   .map(x => x.key)
            //   .toString();

            const incompleteButton = (
              <button
                className='warn'
                onClick={() => {
                  this.setState({
                    Filter: [{ id: "requestStatusName", value: -1 }],
                    StartDate: null,
                    EndDate: null,
                  });
                }}
              >
                <div className='text'>In progress</div>
                <div className='badge'>{incompleteCount}</div>
              </button>
            );
            const totalCount = data.length;
            const allButton = (
              <button
                onClick={() => {
                  this.setState({
                  
                    Filter: [],
                    StartDate: null,
                    EndDate: null,
                  });
                }}
              >
                <div className='text'>Total</div>
                <div className='badge'>{totalCount}</div>
              </button>
            );
            const completeCount = data.filter(
              (e) => e.requestStatusName === "COMPL"
            ).length;
            const completeButton = (
              <button
                className='success-alt'
                onClick={() => {
                  this.setState({
                    Filter: [{ id: "requestStatusName", value: "8" }],
                    StartDate: null,
                    EndDate: null,
                  });
                }}
              >
                <div className='text'>Completed</div>
                <div className='badge'>{completeCount}</div>
              </button>
            );
            const cancelledButton = (
              <button
                className='cancel-alt'
                onClick={() => {
                  this.setState({
                    Filter: [{ id: "requestStatusName", value: "9" }],
                    StartDate: null,
                    EndDate: null,
                  });
                }}
              >
                <div className='text'>Cancelled</div>
                <div className='badge'>{cancelledCount}</div>
              </button>
            );
            const ReportButton = (
              <Button
          variant='contained'
          size='small'
          style={{}}
          onClick={this.getReportData}
          className={
            "selectedBtn" 
          }
        >
          Download Report
        </Button>
           
            );
            const pendingCount = data.filter(
              (e) => e.requestStatusName === "PCUST"
            ).length;
            const pendingButton = (
              <button
                className='success'
                onClick={() => {
                  this.setState({
                    Filter: [{ id: "requestStatusName", value: "2" }],
                    StartDate: null,
                    EndDate: null,
                  });
                }}
              >
                <div className='text'>Pending</div>
                <div className='badge'>{pendingCount}</div>
              </button>
            );

            const { filtered, pageRows, pageSize, sortedData, page } = state;

            if (sortedData && sortedData.length > 0) {
              let isFiltered = filtered.length > 0;

              let totalRecords = sortedData.length;

              let recordsCountFrom = page * pageSize + 1;

              let recordsCountTo = recordsCountFrom + pageRows.length - 1;

              if (isFiltered)
                recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
              else
                recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
            } else recordsInfoText = "No records";

            const dashboard = (
              <div className='dashboard'>
                {pendingButton}
                {errorButton}
                {incompleteButton}
                {completeButton}
                {allButton}
                {cancelledButton}
            
                <div className='dashboard-text'>{recordsInfoText}</div>
           
              <div> {ReportButton}</div>
              </div>
            );
            const fromdatefilterctl = (
              <div className='dashboard'>
                <div className='datestaticrange'>
                  <RegListDateFilter
                    onClick={this.dateFilterClicked}
                    filterSel={this.state.filterSelected}
                  ></RegListDateFilter>
                </div>
                <span className='labelMargin'>Registration Date</span>
                <div className='datepickerrangefrom'>
                  {" "}
                  <DatePicker
                    placeholderText='Start Date'
                    isClearable
                    selected={this.state.StartDateFilter}
                    onChange={(entry) => {
                      this.dateFilterClicked("FromDate", entry);
                    }}
                    popperModifiers={{
                      offset: {
                        enabled: true,
                        offset: "-40px, 0px",
                      },
                      preventOverflow: {
                        enabled: true,
                        escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                        boundariesElement: "viewport",
                      },
                    }}
                  />
                </div>
                <div className='datepickerrangeto'>
                  <DatePicker
                    isClearable
                    placeholderText='End Date'
                    selected={this.state.EndDateFilter}
                    onChange={(entry) => {
                      this.dateFilterClicked("ToDate", entry);
                    }}
                    popperModifiers={{
                      offset: {
                        enabled: true,
                        offset: "-40px, 0px",
                      },
                      preventOverflow: {
                        enabled: true,
                        escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                        boundariesElement: "viewport",
                      },
                    }}
                  />
                </div>
              </div>
            );
            return (
              <div>
                {fromdatefilterctl}
                {dashboard}
                {makeTable()}
              </div>
            );
          }}
        </ReactTable>
      </div>
    );
  }

  defaultFilter(filter, row, column) {
    return (
      row[filter.id] &&
      row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
    );
  }

  buildFilter() {
   
    let filterString = "";
    if (this.state.Filters.StatusValue !== "") {
      if (filterString !== "") {
        filterString += "&&";
      }
      let f = Object.assign([], this.state.Filter);
      const { StatusValue } = this.state.Filters;
      f.push({ id: "requestStatusName", value: StatusValue.split("=")[1] });
      this.setState({ Filter: f });
      filterString += this.state.Filters.StatusValue;
      
    }
    if (this.state.Filters.LicenseTypeValue !== "") {
      if (filterString !== "") {
        filterString += "&&";
      }
      filterString += this.state.Filters.LicenseTypeValue;
    }
    if (this.state.Filters.FamilyTypeValue !== "") {
      if (filterString !== "") {
        filterString += "&&";
      }
      filterString += this.state.Filters.FamilyTypeValue;
    }
    if (this.state.Filters.EmailValue !== "") {
      if (filterString !== "") {
        filterString += "&&";
      }
      filterString +=
        "EmailID=" + JSON.stringify(this.state.Filters.EmailValue);
    }
    if (this.state.Filters.AccountName !== "") {
      if (filterString !== "") {
        filterString += "&&";
      }
      filterString +=
        "CompanyDetail.CompanyName=" +
        JSON.stringify(this.state.Filters.AccountName);
    }
    if (this.state.Filters.ClaimTypeValue !== "") {
      if (filterString !== "") {
        filterString += "&&";
      }
      filterString +=
        "ClaimTypeId=" + JSON.stringify(this.state.Filters.ClaimTypeValue);
    }
    if (this.state.Filters.EntitlementValue !== "") {
      if (filterString !== "") {
        filterString += "&&";
      }
      filterString +=
        "PakId=" + JSON.stringify(this.state.Filters.EntitlementValue);
    }
    if (this.state.Filters.DateFromValue !== "") {
      if (filterString !== "") {
        filterString += "&&";
      }
      filterString +=
        "CreationTime.Date>=" +
        JSON.stringify(this.state.Filters.DateFromValue);
    }
    if (this.state.Filters.DateToValue !== "") {
      if (filterString !== "") {
        filterString += "&&";
      }
      filterString +=
        "CreationTime.Date<=" + JSON.stringify(this.state.Filters.DateToValue);
    }

    return filterString;
  }

  getInitial(useFilter,isAll=false) {
   
    let filterString="";
    if(!isAll)
    {
      filterString= this.buildFilter();
    }
   

    
if(isAll)
{
  let f = this.state.Filters;
  if(this.state.Filter){
    let emailIDForSearch = this.state.Filter.find(o => o.id === 'emailID');
    if (emailIDForSearch) {
      f.EmailValue=emailIDForSearch.value;
    }
    let entitlementForSearch = this.state.Filter.find(o => o.id === 'entitlement');
    if (entitlementForSearch) {
      f.EntitlementValue=entitlementForSearch.value;
    }
    let companyForSearch = this.state.Filter.find(o => o.id === 'companyDetail.companyName');
    if (companyForSearch) {
      f.AccountName=companyForSearch.value;
    }
    let idForSearch = this.state.Filter.find(o => o.id === 'id');
    if (idForSearch) {
      f.id=idForSearch.value;
    }
   
  }

  filterString= this.buildFilter();
}
let options = {
  params: {
    //take: this.state.Take,
    filter: filterString,
  },
};

if (!useFilter) {
  delete options.params;
}
    adalApiFetch(axios, api.GET_REGISTRATIONS, options) //testData.json api.GET_REGISTRATIONS
      .then((response) => {
        const modifiedData = response.data.records.map((r) => {
          r.creationTime = formatDate(new Date(r.creationTime));
          r.creationTimeTo = formatDate(new Date(r.creationTime));
          r.creationTimeFrom = formatDate(new Date(r.creationTime));
          r.entitlement = r.pakid && r.pakid !== "" ? r.pakid : r.serialNumber;

          return r;
        });

        this.setState({
          IsLoaded: true,
          Registrations: modifiedData,
          Count: response.data.totalCount,
          DisplayedCount: this.state.Take,
          GetMoreEnabled: true,
        });
      })
      .catch((error) => {
        console.log(error.response);
        toastError(error.response);
      });
  }
  getReportData() {
   
    let filterString = "";
    
   
    if (this.state.Filters.DateFromValue !== "") {
      if (filterString !== "") {
        filterString += "&&";
      }
      filterString +=
        "CreationTime.Date>=" +
        JSON.stringify(this.state.Filters.DateFromValue);
    }
    if (this.state.Filters.DateToValue !== "") {
      if (filterString !== "") {
        filterString += "&&";
      }
      filterString +=
        "CreationTime.Date<=" + JSON.stringify(this.state.Filters.DateToValue);
    }

    

    let options = {
      params: {
        //take: this.state.Take,
       filter:filterString
      },
    };

   
    adalApiFetch(axios, api.GET_REPORTDATA, options) //testData.json api.GET_REGISTRATIONS
      .then((response) => {
       
        var newData = [];
if(response.data.records.length>0)
{


        for (var i = 0; i < response.data.records.length; i++) {
    
            if (response.data.records[i].id ) {
              
                newData.push({
                  'RegID': response.data.records[i].id,
                  'EmailID': response.data.records[i].emailID,
                  'LicenseTypeName': response.data.records[i].licenseTypeName,
                  'ProductFamilyName': response.data.records[i].productFamilyName,
                  'CompanyName': response.data.records[i].companyDetail?response.data.records[i].companyDetail.companyName:"",
                  'Status': response.data.records[i].requestStatusName,
                 'Error': response.data.records[i].exceptionMessage?response.data.records[i].exceptionMessage:"",
                 'ISEvalToCommerConversion':response.data.records[i].isEvalToCommerConversion,
                 'ISLGSAddUser':response.data.records[i].isLGSAddUser,
                 'ClaimTypeName':response.data.records[i].claimTypeName?response.data.records[i].claimTypeName:"",
                 'Entitlement':response.data.records[i].pakid?response.data.records[i].pakid:"",
                 'CertifiedServerName':response.data.records[i].certifiedServerName?response.data.records[i].certifiedServerName:"",
                 'VDIHyperVisorName':response.data.records[i].vdiHyperVisorName?response.data.records[i].vdiHyperVisorName:"",
                 'NvidiaGPUName':response.data.records[i].nvidiaGPUName?response.data.records[i].nvidiaGPUName:"",
                 'VDISeatName':response.data.records[i].vdiSeatName?response.data.records[i].vdiSeatName:"",
                 'VDIRemotingClientName':response.data.records[i].vdiRemotingClientName?response.data.records[i].vdiRemotingClientName:"",
                 'PrimaryApplicationName':response.data.records[i].primaryApplicationMulName?response.data.records[i].primaryApplicationMulName:"",
                 'Date':response.data.records[i].creationTime?formatDate(new Date(response.data.records[i].creationTime)):""
                });
              }
        
      
            }
          
           
           
           const data=(JSON.parse(JSON.stringify(newData)));
         
         json2excel({ data, name: 'Report', formateDate: 'yyyy/mm/dd' })       

          }
          else
          {
            toastError("No Records Found");
          }
      })
      .catch((error) => {
        
        console.log(error.response);
        toastError(error.response);
      });
  }
  getLookups() {
    adalApiFetch(axios, api.GET_LOOKUPS, {})
      .then((response) => {
        response.data.LicenseType.unshift(this.state.defaultOption);
        response.data.ProductFamily.unshift(this.state.defaultOption);
        response.data.Status.unshift({ key: -1, value: "In progress" });
        response.data.Status.unshift(this.state.defaultOption);
        response.data.ClaimType.unshift(this.state.defaultOption);
        this.setState({
          LicenseTypes: response.data.LicenseType,
          FamilyTypes: response.data.ProductFamily,
          Statuses: response.data.Status,
          ClaimTypes: response.data.ClaimType,
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          console.log(error.response);
        }
      });
  }
}

export default RegistrationList;
