import React, { Component } from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "../../styles/CustomStyles.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import DatePicker from "react-datepicker";

class RegListDateFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bgColor: "",
      startDate: null,
      endDate: null
    };
    this.Today = this.Today.bind(this);
    this.ThisWeek = this.ThisWeek.bind(this);
    this.ThisMonth = this.ThisMonth.bind(this);
    this.All = this.All.bind(this);
  }

  Today() {
    this.props.onClick("Today");
    this.setState({
      backgroundColor: "#76b900"
    });
  }
  ThisWeek() {
    this.props.onClick("This Week");
  }
  ThisMonth() {
    this.props.onClick("This Month");
  }
  All() {
    this.props.onClick("All");
  }
  render() {
    return (
      <div>
        {" "}
        <Button
          variant='contained'
          size='small'
          style={{}}
          onClick={this.Today}
          className={
            this.props.filterSel === "Today" ? "selectedBtn" : "defaultBtn"
          }
        >
          Today
        </Button>
        <Button
          variant='contained'
          onClick={this.ThisWeek}
          size='small'
          style={{ "margin-left": "1em" }}
          className={
            this.props.filterSel === "This Week" ? "selectedBtn" : "defaultBtn"
          }
        >
          This Week
        </Button>
        <Button
          variant='contained'
          size='small'
          onClick={this.ThisMonth}
          style={{
            backgroundColor: "#76b900",
            color: "white",
            "margin-left": "1em"
          }}
          className={
            this.props.filterSel === "This Month" ? "selectedBtn" : "defaultBtn"
          }
        >
          This Month
        </Button>
        <Button
          variant='contained'
          onClick={this.All}
          size='small'
          style={{ "margin-left": "1em" }}
          className={
            this.props.filterSel === "All" ? "selectedBtn" : "defaultBtn"
          }
        >
          All
        </Button>
      </div>
    );
  }
}
export default RegListDateFilter;
