export default function formatDate(date) {
  //   var monthNames = [
  //     "January",
  //     "February",
  //     "March",
  //     "April",
  //     "May",
  //     "June",
  //     "July",
  //     "August",
  //     "September",
  //     "October",
  //     "November",
  //     "December"
  //   ];

  var day = date.getDate();
  var monthIndex = date.getMonth();
  var year = date.getFullYear();


    var hour = format_two_digits(date.getHours());
    var minutes = format_two_digits(date.getMinutes());
    var sec = format_two_digits(date.getSeconds());


  //return monthNames[monthIndex] + "/" + day + "/" + year;
  return monthIndex + 1 + "/" + day + "/" + year + " " + hour + ":" + minutes + ":" + sec;
}


function format_two_digits(n) {
    return n < 10 ? '0' + n : n;
}
