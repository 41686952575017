import React from "react";
//import { Link } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Logo from "./images/nvidia-logo.png";
import Bg from "./images/particles.jpg";

const AppHeader = props => {
  var sectionStyle = {
    backgroundImage: `url(${Bg})`
  };

  return (
      <header>
      <div className="header-content" style={sectionStyle}>
             <img className="header-logo" src={Logo} alt="NVIDIA Logo" />
             <div className="header-text-badge">
                  <h1 className="header-text"> Enterprise Account Registration Admin</h1>
             <ToastContainer />

      </div>
      <div className="header-badge"> </div>

      </div>
      {/* <div className="header-content">
        {props.includeNav && (
          <nav>
            <Link to="/">Home</Link>
          </nav>
        )}
      </div> */}
      </header>
  );
};

AppHeader.defaultProps = {
  includeNav: true
};

export default AppHeader;
