import {  toast } from 'react-toastify';


export const toastSuccess = (message) => {
    toast.success(message, { position: toast.POSITION.TOP_RIGHT, bodyClassName: 'whiteText', autoClose:1500 })
}

export const toastError = (message) => {
    toast.error(message, { position: toast.POSITION.TOP_RIGHT, className: "toast-margin", autoClose:false })

}

export const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        position: 'absolute',
    }
};