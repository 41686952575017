import { Component } from "react";
import React from "react";
import "../../styles/CustomStyles.css";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import ListGroup from "react-bootstrap/ListGroup";

class UserDetailsView extends Component {
  state = {};
  render() {
    var isEval = false;
    var isDGX = false;

    if (this.props.LicenseTypeID === 1) {
      isEval = true;
    } else if (
     ( this.props.LicenseTypeID === 2 &&
      this.props.ProductFamilyName.toUpperCase() === "DGX")  || this.props.RwaID===2
    ) {
      isDGX = true;
    }
    if (isEval) {
      return (
        <EvalData
          companyName={this.props.companyName}
          companyCountryName={this.props.CountryName}
          companyStreet1Name={this.props.companyStreet1Name}
          companyStreet2Name={this.props.companyStreet2Name}
          companyCityName={this.props.companyCityName}
          companyStateName={this.props.companyStateName}
          companyIndustryName={this.props.companyIndustryName}
          companyZipCode={this.props.companyZipCode}
          CountryName={this.props.CountryName}
          Street1Name={this.props.Street1Name}
          Street2Name={this.props.Street2Name}
          CityName={this.props.CityName}
          StateName={this.props.StateName}
          JobRoleName={this.props.JobRoleName}
          Phone={this.props.Phone}
          ZipCode={this.props.ZipCode}
          CertifiedServerName={this.props.CertifiedServerName}
          VDIHyperVisorName={this.props.VDIHyperVisorName}
          VDISeatsName={this.props.VDISeatsName}
          NVIDIAGPUName={this.props.NVIDIAGPUName}
          RemotingClientName={this.props.RemotingClientName}
          PrimaryApplicationName={this.props.PrimaryApplicationName}
        />
      );
    } else if (isDGX)
      return (
        <CommercialDGXData
          companyName={this.props.companyName}
          companyCountryName={this.props.CountryName}
          companyStreet1Name={this.props.companyStreet1Name}
          companyStreet2Name={this.props.companyStreet2Name}
          companyCityName={this.props.companyCityName}
          companyStateName={this.props.companyStateName}
          companyIndustryName={this.props.companyIndustryName}
          companyZipCode={this.props.companyZipCode}
          CountryName={this.props.CountryName}
          Street1Name={this.props.Street1Name}
          Street2Name={this.props.Street2Name}
          CityName={this.props.CityName}
          StateName={this.props.StateName}
          JobRoleName={this.props.JobRoleName}
          Phone={this.props.Phone}
          ZipCode={this.props.ZipCode}
          contactTypeName={this.props.ngccontactTypeName}
          ngcEmailID={this.props.ngcEmailID}
          ngcFirstName={this.props.ngcFirstName}
          ngcLastName={this.props.ngcLastName}
          ngcShortCompanyName={this.props.ngcShortCompanyName}
        />
      );
    else
      return (
        <CommercialAnyData
          companyName={this.props.companyName}
          companyCountryName={this.props.CountryName}
          companyStreet1Name={this.props.companyStreet1Name}
          companyStreet2Name={this.props.companyStreet2Name}
          companyCityName={this.props.companyCityName}
          companyStateName={this.props.companyStateName}
          companyIndustryName={this.props.companyIndustryName}
          companyZipCode={this.props.companyZipCode}
          CountryName={this.props.CountryName}
          Street1Name={this.props.Street1Name}
          Street2Name={this.props.Street2Name}
          CityName={this.props.CityName}
          StateName={this.props.StateName}
          JobRoleName={this.props.JobRoleName}
          Phone={this.props.Phone}
          ZipCode={this.props.ZipCode}
        ></CommercialAnyData>
      );
  }
}

export default UserDetailsView;
class CommercialAnyData extends React.Component {
  render() {
    return (
      <div>
        <CardGroup>
          <Card border='success' style={{ width: "10rem" }}>
            <Card.Header>Company Details</Card.Header>
            <ListGroup>
              {this.props.companyName ? (
                <ListGroup.Item> {this.props.companyName}</ListGroup.Item>
              ) : (
                ""
              )}
              {this.props.companyCountryName ? (
                <ListGroup.Item>
                  {" "}
                  {this.props.companyCountryName}
                </ListGroup.Item>
              ) : (
                ""
              )}
              {this.props.companyStreet1Name ? (
                <ListGroup.Item>
                  {" "}
                  {this.props.companyStreet1Name}
                </ListGroup.Item>
              ) : (
                ""
              )}

              {this.props.companyStreet2Name ? (
                <ListGroup.Item>
                  {" "}
                  {this.props.companyStreet2Name}
                </ListGroup.Item>
              ) : (
                ""
              )}

              {this.props.companyCityName ? (
                <ListGroup.Item> {this.props.companyCityName}</ListGroup.Item>
              ) : (
                ""
              )}

              {this.props.companyStateName || this.props.companyZipCode ? (
                <ListGroup.Item>
                  {" "}
                  {this.props.companyStateName},{this.props.companyZipCode}
                </ListGroup.Item>
              ) : (
                ""
              )}

              {this.props.companyIndustryName ? (
                <ListGroup.Item>
                  {this.props.companyIndustryName}
                </ListGroup.Item>
              ) : (
                ""
              )}
            </ListGroup>
          </Card>
          <Card border='success' style={{ width: "10rem" }}>
            <Card.Header>Primary Contact Details</Card.Header>
            <ListGroup>
              {this.props.CountryName ? (
                <ListGroup.Item>{this.props.CountryName}</ListGroup.Item>
              ) : (
                ""
              )}
              {this.props.Street1Name ? (
                <ListGroup.Item>{this.props.Street1Name}</ListGroup.Item>
              ) : (
                ""
              )}
              {this.props.Street2Name ? (
                <ListGroup.Item>{this.props.Street2Name}</ListGroup.Item>
              ) : (
                ""
              )}
              {this.props.CityName ? (
                <ListGroup.Item>{this.props.CityName}</ListGroup.Item>
              ) : (
                ""
              )}
              {this.props.StateName || this.props.ZipCode ? (
                <ListGroup.Item>
                  {" "}
                  {this.props.StateName},{this.props.ZipCode}
                </ListGroup.Item>
              ) : (
                ""
              )}

              {this.props.Phone ? (
                <ListGroup.Item>{this.props.Phone}</ListGroup.Item>
              ) : (
                ""
              )}
              {this.props.JobRoleName ? (
                <ListGroup.Item>{this.props.JobRoleName}</ListGroup.Item>
              ) : (
                ""
              )}
            </ListGroup>
          </Card>
        </CardGroup>
      </div>
    );
  }
}

class EvalData extends React.Component {
  render() {
    return (
      <div>
        <CardGroup>
          <Card border='success' style={{ width: "10rem" }}>
            <Card.Header>Company Details</Card.Header>
            <ListGroup>
              {this.props.companyName ? (
                <ListGroup.Item> {this.props.companyName}</ListGroup.Item>
              ) : (
                ""
              )}
              {this.props.companyCountryName ? (
                <ListGroup.Item>
                  {" "}
                  {this.props.companyCountryName}
                </ListGroup.Item>
              ) : (
                ""
              )}
              {this.props.companyStreet1Name ? (
                <ListGroup.Item>
                  {" "}
                  {this.props.companyStreet1Name}
                </ListGroup.Item>
              ) : (
                ""
              )}

              {this.props.companyStreet2Name ? (
                <ListGroup.Item>
                  {" "}
                  {this.props.companyStreet2Name}
                </ListGroup.Item>
              ) : (
                ""
              )}

              {this.props.companyCityName ? (
                <ListGroup.Item> {this.props.companyCityName}</ListGroup.Item>
              ) : (
                ""
              )}

              {this.props.companyStateName || this.props.companyZipCode ? (
                <ListGroup.Item>
                  {" "}
                  {this.props.companyStateName},{this.props.companyZipCode}
                </ListGroup.Item>
              ) : (
                ""
              )}

              {this.props.companyIndustryName ? (
                <ListGroup.Item>
                  {this.props.companyIndustryName}
                </ListGroup.Item>
              ) : (
                ""
              )}
            </ListGroup>
          </Card>
          <Card border='success' style={{ width: "10rem" }}>
            <Card.Header>Primary Contact Details</Card.Header>
            <ListGroup>
              {this.props.CountryName ? (
                <ListGroup.Item>{this.props.CountryName}</ListGroup.Item>
              ) : (
                ""
              )}
              {this.props.Street1Name ? (
                <ListGroup.Item>{this.props.Street1Name}</ListGroup.Item>
              ) : (
                ""
              )}
              {this.props.Street2Name ? (
                <ListGroup.Item>{this.props.Street2Name}</ListGroup.Item>
              ) : (
                ""
              )}
              {this.props.CityName ? (
                <ListGroup.Item>{this.props.CityName}</ListGroup.Item>
              ) : (
                ""
              )}
              {this.props.StateName || this.props.ZipCode ? (
                <ListGroup.Item>
                  {" "}
                  {this.props.StateName},{this.props.ZipCode}
                </ListGroup.Item>
              ) : (
                ""
              )}

              {this.props.Phone ? (
                <ListGroup.Item>{this.props.Phone}</ListGroup.Item>
              ) : (
                ""
              )}
              {this.props.JobRoleName ? (
                <ListGroup.Item>{this.props.JobRoleName}</ListGroup.Item>
              ) : (
                ""
              )}
            </ListGroup>
          </Card>
          <Card border='success' style={{ width: "10rem" }}>
            <Card.Header>Environment</Card.Header>
            <ListGroup>
              {this.props.CertifiedServerName ? (
                <ListGroup.Item>
                  {" "}
                  <span style={{ fontWeight: "bold" }}>
                    Certified Server:
                  </span>{" "}
                  {this.props.CertifiedServerName}
                </ListGroup.Item>
              ) : (
                ""
              )}

              {this.props.VDIHyperVisorName ? (
                <ListGroup.Item>
                  {" "}
                  <span style={{ fontWeight: "bold" }}> VDI Hypervisor: </span>
                  {this.props.VDIHyperVisorName}
                </ListGroup.Item>
              ) : (
                ""
              )}
              {this.props.NVIDIAGPUName ? (
                <ListGroup.Item>
                  {" "}
                  <span style={{ fontWeight: "bold" }}>NVIDIA GPUs:</span>
                  {this.props.NVIDIAGPUName}
                </ListGroup.Item>
              ) : (
                ""
              )}

              {this.props.VDISeatsName ? (
                <ListGroup.Item>
                  {" "}
                  <span style={{ fontWeight: "bold" }}>VDI Seats:</span>
                  {this.props.VDISeatsName}
                </ListGroup.Item>
              ) : (
                ""
              )}
              {this.props.RemotingClientName ? (
                <ListGroup.Item>
                  {" "}
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    VDI Remoting Client:
                  </span>
                  {this.props.RemotingClientName}
                </ListGroup.Item>
              ) : (
                ""
              )}
              {this.props.PrimaryApplicationName ? (
                <ListGroup.Item>
                  {" "}
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    Primary Application:
                  </span>
                  {this.props.PrimaryApplicationName}
                </ListGroup.Item>
              ) : (
                ""
              )}
            </ListGroup>
          </Card>
        </CardGroup>
      </div>
    );
  }
}

class CommercialDGXData extends React.Component {
  render() {
    return (
      <div>
        <CardGroup>
          <Card border='success' style={{ width: "10rem" }}>
            <Card.Header>Company Details</Card.Header>
            <ListGroup>
              {this.props.companyName ? (
                <ListGroup.Item> {this.props.companyName}</ListGroup.Item>
              ) : (
                ""
              )}
              {this.props.companyCountryName ? (
                <ListGroup.Item>
                  {" "}
                  {this.props.companyCountryName}
                </ListGroup.Item>
              ) : (
                ""
              )}
              {this.props.companyStreet1Name ? (
                <ListGroup.Item>
                  {" "}
                  {this.props.companyStreet1Name}
                </ListGroup.Item>
              ) : (
                ""
              )}

              {this.props.companyStreet2Name ? (
                <ListGroup.Item>
                  {" "}
                  {this.props.companyStreet2Name}
                </ListGroup.Item>
              ) : (
                ""
              )}

              {this.props.companyCityName ? (
                <ListGroup.Item> {this.props.companyCityName}</ListGroup.Item>
              ) : (
                ""
              )}

              {this.props.companyStateName || this.props.companyZipCode ? (
                <ListGroup.Item>
                  {" "}
                  {this.props.companyStateName},{this.props.companyZipCode}
                </ListGroup.Item>
              ) : (
                ""
              )}

              {this.props.companyIndustryName ? (
                <ListGroup.Item>
                  {this.props.companyIndustryName}
                </ListGroup.Item>
              ) : (
                ""
              )}
            </ListGroup>
          </Card>
          <Card border='success' style={{ width: "10rem" }}>
            <Card.Header>Primary Contact Details</Card.Header>
            <ListGroup>
              {this.props.CountryName ? (
                <ListGroup.Item>{this.props.CountryName}</ListGroup.Item>
              ) : (
                ""
              )}
              {this.props.Street1Name ? (
                <ListGroup.Item>{this.props.Street1Name}</ListGroup.Item>
              ) : (
                ""
              )}
              {this.props.Street2Name ? (
                <ListGroup.Item>{this.props.Street2Name}</ListGroup.Item>
              ) : (
                ""
              )}
              {this.props.CityName ? (
                <ListGroup.Item>{this.props.CityName}</ListGroup.Item>
              ) : (
                ""
              )}
              {this.props.StateName || this.props.ZipCode ? (
                <ListGroup.Item>
                  {" "}
                  {this.props.StateName},{this.props.ZipCode}
                </ListGroup.Item>
              ) : (
                ""
              )}

              {this.props.Phone ? (
                <ListGroup.Item>{this.props.Phone}</ListGroup.Item>
              ) : (
                ""
              )}
              {this.props.JobRoleName ? (
                <ListGroup.Item>{this.props.JobRoleName}</ListGroup.Item>
              ) : (
                ""
              )}
            </ListGroup>
          </Card>
          <Card border='success' style={{ width: "10rem" }}>
            <Card.Header>NGC Container Registry Administrator</Card.Header>
            <ListGroup>
              {this.props.contactTypeName ? (
                <ListGroup.Item>{this.props.contactTypeName}</ListGroup.Item>
              ) : (
                ""
              )}
              {this.props.ngcEmailID ? (
                <ListGroup.Item>{this.props.ngcEmailID}</ListGroup.Item>
              ) : (
                ""
              )}

              {this.props.ngcFirstName ? (
                <ListGroup.Item>{this.props.ngcFirstName}</ListGroup.Item>
              ) : (
                ""
              )}
              {this.props.ngcLastName ? (
                <ListGroup.Item>{this.props.ngcLastName}</ListGroup.Item>
              ) : (
                ""
              )}
              {this.props.ngcShortCompanyName ? (
                <ListGroup.Item>
                  {this.props.ngcShortCompanyName}
                </ListGroup.Item>
              ) : (
                ""
              )}
            </ListGroup>
          </Card>
        </CardGroup>
      </div>
    );
  }
}
