import React from "react";
import Popup from "reactjs-popup";
import "../styles/Collapsible.scss";
import { adalApiFetch } from "../adalConfig";

import axios from "axios";
import * as api from "../constants/api";
import formatDate from "../util/Date";
import { toastError } from "../util/CommonFunctions";

import Table from "@material-ui/core/Table";

import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

export default class AuditTrailPopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false, AuditTrailData: [], isLoaded: false };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }
  openModal() {
    this.setState({ open: true });
    this.getAuditTrailDetails(this.props.regID);
  }
  closeModal() {
    this.setState({ open: false, AuditTrailData: [], isLoaded: false });
  }
  getAuditTrailDetails(regID) {
    let options = {
      params: {
        //take: this.state.Take,
        regID: regID,
      },
    };

    adalApiFetch(axios, api.GET_AUDITTRAIL, options) //testData.json api.GET_REGISTRATIONS
      .then((response) => {
        const auditFilterData = response.data.map((r) => {
          r.modifiedDate = formatDate(new Date(r.modifiedDate));
          return r;
        });
        this.setState({ AuditTrailData: auditFilterData, isLoaded: true });
      })
      .catch((error) => {
        console.log(error.response);
        toastError(error.response);
      });
  }
  render() {
    return (
      <div className='auditTrailSty'>
        <button
          className='btn small auditBtn auditBtnPad'
          onClick={this.openModal}
        >
          Audit
        </button>
        <Popup
          open={this.state.open}
          closeOnDocumentClick
          onClose={this.closeModal}
        >
          <div className='modal'>
            <a className='close' onClick={this.closeModal}>
              &times;
            </a>
            <TableContainer component={Paper}>
              <Table aria-label='simple table'>
                <TableHead>
                  <TableRow className='auditTableTitleN'>
                    {" "}
                    Registration ID : {this.props.regID}
                  </TableRow>

                  <TableRow>
                    <TableCell align='left'>Date</TableCell>
                    <TableCell align='left'>Field</TableCell>
                    <TableCell align='left'>User</TableCell>
                    <TableCell align='left'>OldValue</TableCell>
                    <TableCell align='left'>NewValue</TableCell>
                    <TableCell align='left'>Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.AuditTrailData.map((row) => (
                    <TableRow key={row.modifiedDate}>
                      <TableCell align='left'>{row.modifiedDate}</TableCell>
                      <TableCell align='left'>{row.fieldName}</TableCell>
                      <TableCell align='left'>{row.userName}</TableCell>

                      <TableCell align='left'>{row.oldValue}</TableCell>
                      <TableCell align='left'>{row.newValue}</TableCell>
                      <TableCell className='DescriptionWrap' align='left'>
                        {row.description}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {this.state.AuditTrailData.length === 0 && this.state.isLoaded && (
              <div>No Data Found!!</div>
            )}
          </div>
        </Popup>
      </div>
    );
  }
}
