import React from "react";
import { slide as Menu } from 'react-burger-menu'
import "../styles/App.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faTasks,  faHome } from '@fortawesome/free-solid-svg-icons'

const NavMenu = props => {

    var menu_styles = {
        bmBurgerButton: {
            position: 'fixed',
            width: '20px',
            height: '18px',
            left: '25px',
            top: '55px',
        },
        bmBurgerBars: {
            background: '#76b900'
        },
        bmBurgerBarsHover: {
            background: '#a90000'
        },
        bmCrossButton: {
            height: '24px',
            width: '24px'
        },
        bmCross: {
            background: '#bdc3c7'
        },
        bmMenuWrap: {
            position: 'fixed',
            height: '100%'
        },
        bmMenu: {
            background: 'white',
            height: 'auto',
            padding: '2.5em 1.5em 0',
            marginTop:'-10%',
            fontSize: '1.15em'
        },
        bmMorphShape: {
            fill: '#373a47'
        },
        bmItemList: {
            color: '#b8b7ad',
        },
        bmItem: {
            display: 'inline-block',
            color: 'black',
            marginBottom: '10px',
            textDecoration:'none'
        },
        bmOverlay: {
            background: 'rgba(0, 0, 0, 0.3)'
        },
    }


  return (
      <div >
          <Menu isOpen={false} styles={menu_styles} width={250}  >
              <a id="home" className="menu-item" href="/"><FontAwesomeIcon icon={faHome} color="#76b900" />  Home </a>
              <a id="lictype" className="menu-item" href="/licType"> <FontAwesomeIcon icon={faTasks} color="#76b900" />  License Type</a>
              <a id="productfamily" className="menu-item" href="/prodfamily"><FontAwesomeIcon icon={faTasks} color="#76b900" /> Product Family</a>
              <a id="rwamapping" className="menu-item" href="/rwamapping"> <FontAwesomeIcon icon={faTasks} color="#76b900" />  RWA Mapping</a>
              <a id="pcustAssignment" className="menu-item" href="/pcustassignment"> <FontAwesomeIcon icon={faTasks} color="#76b900" />  Pcust Assignment</a>
              <a id="pcustMatching" className="menu-item" href="/pcustmatching"> <FontAwesomeIcon icon={faTasks} color="#76b900" />  Pcust Matching</a>
          </Menu>
      </div>
  );
};

 

export default NavMenu;
