import React from "react";
import { PropTypes } from "prop-types";
const Input = props => {
  return (
      <div className={"textInputDiv"}>
        <label className={props.className}>
        {props.labelText}:{" "}
        
        <input
              id={props.id}
              className={props.inputClassName}
              type={props.type}
              required={props.isRequired}
              defaultValue={props.defaultValue}
              onChange={props.onChange}
              onBlur={props.onBlur}
              disabled={props.readOnly}
              readOnly={props.readOnly}
              autoFocus={props.autoFocus}
              value={props.value}
              />
          </label>
    </div>
  );
};

Input.propTypes = {
  isRequired: PropTypes.bool,
  autoFocus: PropTypes.bool,
  labelText: PropTypes.string.isRequired,
  type: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  readOnly: PropTypes.bool,
  id: PropTypes.string,
};

Input.defaultProps = {
  type: "text",
  readOnly: false,
  isRequired: false
};

export default Input;
