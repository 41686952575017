import React, { Component, Fragment } from "react";
import axios from "axios";
import * as api from "./constants/api";
import { adalApiFetch } from "./adalConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import formatDate from "./util/Date";
import { toastSuccess, toastError } from "./util/CommonFunctions";
//import NavMenu from '../src/components/NavMenu'
import Select from "./util/Select";
import { isArray } from "util";
import Collapsible from "react-collapsible";
import "font-awesome/css/font-awesome.min.css";
import "../src/styles/Collapsible.scss";
import Checkbox from "@material-ui/core/Checkbox";
import CancelReg from "./components/RegDetailsView/CancelReg";
import { FaEdit, FaCheck, FaTimes } from "react-icons/fa";
import RegUrlPopOver from "./components/RegDetailsView/RegUrl";

// .react-grid-item{
//  background-color:white;
//  border:1px solid black;
//}
// import "../node_modules/react-grid-layout/css/styles.css";
// import "../node_modules/react-resizable/css/styles.css";

import UserDetailsView from "./components/RegDetailsView/RegDetails";

class RegistrationDetail extends Component {
  constructor(props) {
    super(props);

    this.cancelToken = axios.CancelToken;
    this.source = this.cancelToken.source();

    // const assignView =
    //   props.location.state && props.location.state.assignView
    //     ? props.location.state.assignView
    //     : false;

    this.defaultStateOption = {
      id: 0,
      code: "",
      stateName: "--Select--",
    };
    this.defaultOption = { key: 0, value: "Select an Option" };
    this.defaultCountryOption = {
      id: 0,
      code: "",
      countryName: "Select a Country",
    };
  //   this.defaultAccountOption =[ {
  //     accountID: 0,
     
  //     accountName: "Select a Account",
     
  //   },
  //   {
  //     accountID: 1,
     
  //     accountName: "Select a Propspect",
     
  //   }
  // ];

    this.state = {
      Registration: {
        companyDetail: {},
      },
      Matches: [],
      MatchesLoaded: false,
      AssignView:
            props.location && props.location.state && props.location.state.assignView
                ? props.location.state.assignView
                : props.assignView ? props.assignView : false,
      ShowRerun: false,
      isCancelledTypeReq: false,
      AllowRerun: true,
      ShowMatchingCount: 5,
      AdminOverride: false,
      StatusLoaded: false,
      IsPCUST: false,
      OrchStatus: {},
      AdminOverrideData: {
        Name: "",
        Street1: "",
        Street2: "",
        City: "",
        Country: "",
        CountryCode: "",
        State: "",
        Zip: 0,
        SDFCID: "",
        Website:"",
        AccountHierarchy:"",
        AccountHierarchyName:""
      },
      NavBack: false,
      Countries: [this.defaultCountryOption],
      AccountHierarchy: [ {
        accountID: 0,
       
        accountName: "Select a Account",
       
      },
      {
        accountID: 1,
       
        accountName: "Prospect",
       
      },
      {
        accountID: 2,
       
        accountName: "Division",
       
      },
      {
        accountID: 3,
       
        accountName: "Headquarters",
       
      }
    ],
      SelectedCountryCode: "",
      States: [this.defaultStateOption],
      OrgData: {
        CompanyCountry: 0,
        CompanyState: 0,
      },
      isPecIDEditable: false,
      isPecIDEditing: false,
      origPECIDBeforeUpdate: "",
    };
  }
  componentDidMount() {
    this.getCountryData();
    this.getReg();
  }
  componentWillUnmount() {
    this.source.cancel("component unmounted");
  }
  render() {
    const {
      Registration: r,
      AssignView: assignView,
      Matches: matches,
      OrchStatus: os,
      StatusLoaded: statusLoaded,
      ShowRerun: showRerun,
      isCancelledTypeReq: isReqCancelled,
      AllowRerun: allowRerun,
      AdminOverride: adminOverride,
      AdminOverrideData: adminOverrideData,
      ShowMatchingCount: showMatchingCount,
      MatchesLoaded: matchesLoaded,
      IsPCUST: isPCUST,
    } = this.state;
    const { canProcessReg, canCreateCust, canViewCustAssign } = this.props;

    const styles = {
      display: "inline",
    };
    const innerStyles = {
      marginTop: "5px",
      marginBottom: "5px",
      height: "25px",
      width: "179px",
      marginLeft: "-4px",
    };
    const innerStyle2 = {
      marginTop: "5px",
      marginBottom: "5px",
      height: "25px",
      width: "179px",
      marginLeft: "15px",
    };
    const topMargin20 = {
      marginLeft: "20px",
    };
    const errorLabelStyle = {
      marginLeft: "10px",
      marginTop:"0px",
      marginLeft: "10px",
    marginTop: "0px",
    backgroundColor: "red",
    marginBottom: "9px",
    };
    const errorTextStyle = {
      margin: "5px",
     color:"white",
     fontSize:"14px"
    };

    const matchesList = matches.map((m) => {
      return (
        <div className='match-row' key={m.zsfdcid}>
          <div>{m.zsfdcid}</div>
          <div>{m.kunnr}</div>
          <div>{m.namE1}</div>
          <div>{m.stras}</div>
          <div>{m.orT01}</div>
          <div>{m.lanD1}</div>
          <div>{parseInt(m.matchinG_PERC)}</div>
          <div>
            {this.props.canAssignCust && (
              <button
                className='btn'
                onClick={() =>
                  this.handleAssignClick(m.zsfdcid, m.namE1, m.kunnr, m.lanD1)
                }
              >
                Assign
              </button>
            )}
          </div>
        </div>
      );
    });

    return (
      <div className='content'>
        <div>
          <div className='flex'>
            <h2 className='details-page-header'>
              Registration {r.id}
              <small>
                {r && r.creationTime && formatDate(new Date(r.creationTime))}
              </small>
            </h2>
            <div className='cancelRegStyles'>
              {" "}
              <CancelReg
                statusName={this.state.Registration.requestStatusName}
                regID={this.state.Registration.id}
                viewCancelStatus={this.props.viewCancelStatus}
              ></CancelReg>
            </div>
            {this.props.popup ? '' :
                <div className='flex-fill text-right'>
                    <button
                        className='btn'
                        onClick={() => this.props.history.goBack()}
                    >
                        {this.state.AssignView ? "Cancel" : "Back"}
                    </button>
                </div>
            }
          </div>
          <div className='flex'>
            <h2 className='details-reg-header'>
              <small>of type</small>
              {r.licenseTypeName} {r.productFamilyName}
            </h2>
            <RegUrlPopOver url={this.state.Registration.regUrl}></RegUrlPopOver>
         
            {(r.requestStatusId === 2 &&
            r.ngcpecid && r.derivedPECID &&r.ngcpecid.toUpperCase()!==r.derivedPECID.toUpperCase()&&
              <div style={errorLabelStyle}>
            <span  style={errorTextStyle} class="MuiButton-label">PEC in NGC and SFDC are not Matching. Please Update to the Customer with NVES Assistance.</span>
           
            </div>
  )}
          </div>
          {/* start from here */}
          <div className='mainwrapper'>
            <div className='rowcssstyling'>
              {r.licenseTypeId !== 1 && r.pakid && r.pakid !== "" && (
                <div className='columncssstyling'>
                  <label className='block'>Entitlement</label>
                  <div>
                    <div title='PakId'>{r.pakid}</div>
                  </div>
                </div>
              )}

              {r.emspkId && r.emspkId !== "" && (
                <div className='columncssstyling'>
                  <label className='block'>EMS PK ID</label>
                  <div>
                    <div title='emspkId'>{r.emspkId}</div>
                  </div>
                </div>
              )}

              {r.pecid && r.pecid !== "" && !this.state.isPecIDEditable && (
                <div className='columncssstyling'>
                  <label className='block'>PEC ID</label>
                  <div>
                    <div title='PecId'>
                      {r.pecid}{" "}
                      {!(r.requestStatusId === 8 || r.requestStatusId === 9) &&
                        this.props.canCreateRWAMapping && (
                          <FaEdit onClick={this.handlePECIDUpdate} />
                        )}{" "}
                    </div>
                  </div>
                </div>
              )}

              {this.state.isPecIDEditable && (
                <div className='columncssstyling'>
                  <label className='block'>PEC ID</label>
                  <div>
                    <div title='PecId'>
                      {" "}
                      <input
                        type='text'
                        value={r.pecid}
                        onChange={this.handlePECIDChange}
                      />{" "}
                      <FaCheck onClick={this.handleSaveForPECID} />{" "}
                      <FaTimes onClick={this.handleCancelForPECID} />{" "}
                    </div>
                  </div>
                </div>
              )}

              {r.derivedPECID && r.derivedPECID !== "" && (
                <div className='columncssstyling'>
                  <label className='block'>Derived PEC ID</label>
                  <div>
                    <div title='DerivedPecId'>{r.derivedPECID}</div>
                  </div>
                </div>
              )}

              {r.serialNumber && r.serialNumber !== "" && (
                <div className='columncssstyling'>
                  <label className='block'>Serial Number</label>
                  <div>
                    <div title='Serial'>{r.serialNumber}</div>
                  </div>
                </div>
              )}

              {r.orchestrationId && r.orchestrationId !== "" && (
                <div className='columncssstyling'>
                  <label className='block'>Orchestration ID</label>
                  <div>
                    <div title='orchestration'>{r.orchestrationId}</div>
                  </div>
                </div>
              )}

              <div className='columncssstyling'>
                <label className='block'>Requester Info</label>
                <div>
                  <div>
                    {r.firstName} {r.lastName}
                  </div>
                  <div>{r.emailID}</div>
                </div>
              </div>
              {r.isEvalToCommerConversion && (
                <div className='columncssstyling'>
                  <label className='block'>Target Org</label>
                  <div>
                    {r.isEvalToCommerConversion && (
                      <div title='Target Org'>{r.targetOrgID}</div>
                    )}
                  </div>
                </div>
              )}

              <div className='columncssstyling'>
                <label className='block'>Add User</label>
                <div>
                  {r.isLGSAddUser && (
                    <div title='lgsuser'>
                      <Checkbox
                        checked
                        disabled
                        color='default'
                        inputProps={{
                          "aria-label": "disabled checked checkbox",
                        }}
                      />
                    </div>
                  )}
                  {!r.isLGSAddUser && (
                    <div title='lgsuser'>
                      <Checkbox
                        disabled
                        inputProps={{
                          "aria-label": "disabled  checkbox",
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className='columncssstyling'>
                <label className='block'>Eval To Commercial</label>
                <div>
                  {r.isEvalToCommerConversion && (
                    <div title='EvalToComercial'>
                      <Checkbox
                        checked
                        disabled
                        color='default'
                        inputProps={{
                          "aria-label": "disabled checked checkbox",
                        }}
                      />
                    </div>
                  )}
                  {!r.isEvalToCommerConversion && (
                    <div title='EvalToComercial'>
                      <Checkbox
                        disabled
                        inputProps={{
                          "aria-label": "disabled  checkbox",
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              {r.isEvalToCommerConversion && (
                <div className='columncssstyling'>
                  <label className='block'>Source Org</label>
                  <div>
                    {r.isEvalToCommerConversion && (
                      <div title='Source Org'>{r.sourceOrgID}</div>
                    )}
                  </div>
                </div>
              )}
              {r.licenseTypeId && r.licenseTypeId === 2 && (
                <div className='columncssstyling'>
                  <label className='block'>Claiming Entitlement As</label>
                  <div>
                    <div title='claimType'>{r.claimTypeName}</div>
                  </div>
                </div>
              )}
               {r.productFamilyName && (r.productFamilyName.toUpperCase() === 'H100' || r.productFamilyName.toUpperCase() === 'H800' ||r.productFamilyName.toUpperCase() === 'BLUEFIELD-SW') && (
                <div className='columncssstyling'>
                  <label className='block'>NGC Org Name</label>
                  <div>
                    <div title='orgName'>{r.ngcOrgName}</div>
                  </div>
                </div>
              )}
                {r.productFamilyName && (r.productFamilyName === 'H100'|| r.productFamilyName.toUpperCase() === 'H800' ||r.productFamilyName.toUpperCase() === 'BLUEFIELD-SW') && (
                <div className='columncssstyling'>
                  <label className='block'>NGC PEC ID</label>
                  <div>
                    <div title='orgName'>{r.ngcpecid}</div>
                  </div>
                </div>
              )}
                {r.matchingType && r.productFamilyName && (r.productFamilyName === 'H100'|| r.productFamilyName.toUpperCase() === 'H800' ||r.productFamilyName.toUpperCase() === 'BLUEFIELD-SW') && (
                <div className='columncssstyling'>
                  <label className='block'>MatchingType</label>
                  <div>
                    <div title='orgName'>{r.matchingType}</div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div>
            <div>
              <br></br>

              <Collapsible trigger='Registration Details'>
                <UserDetailsView
                  companyName={
                    this.state.Registration.companyDetail.companyName
                  }
                  companyCountryName={
                    this.state.Registration.companyCountryName
                  }
                  companyStreet1Name={
                    this.state.Registration.companyDetail.street1
                  }
                  companyStreet2Name={
                    this.state.Registration.companyDetail.street2
                  }
                  companyCityName={this.state.Registration.companyDetail.city}
                  companyStateName={this.state.Registration.companyStateName}
                  companyIndustryName={
                    this.state.Registration.companyIndustryName
                  }
                  companyZipCode={this.state.Registration.companyDetail.zipCode}
                  CountryName={this.state.Registration.countryName}
                  Street1Name={this.state.Registration.street1}
                  Street2Name={this.state.Registration.street2}
                  CityName={this.state.Registration.city}
                  StateName={this.state.Registration.stateName}
                  JobRoleName={this.state.Registration.jobRoleName}
                  Phone={this.state.Registration.phone}
                  ZipCode={this.state.Registration.zipCode}
                  CertifiedServerName={
                    this.state.Registration.certifiedServerName
                  }
                  VDIHyperVisorName={this.state.Registration.vdiHyperVisorName}
                  VDISeatsName={this.state.Registration.vdiSeatName}
                  NVIDIAGPUName={this.state.Registration.nvidiaGPUName}
                  RemotingClientName={
                    this.state.Registration.vdiRemotingClientName
                  }
                  PrimaryApplicationName={
                    this.state.Registration.primaryApplicationName
                  }
                  LicenseTypeID={this.state.Registration.licenseTypeId}
                  RwaID={this.state.Registration.rwaId}
                  ProductFamilyName={this.state.Registration.productFamilyName}
                  ngccontactTypeName={this.state.Registration.contactTypeName}
                  ngcEmailID={
                    this.state.Registration.ngcContainerRegistry
                      ? this.state.Registration.ngcContainerRegistry.emailID
                      : ""
                  }
                  ngcFirstName={
                    this.state.Registration.ngcContainerRegistry
                      ? this.state.Registration.ngcContainerRegistry.firstName
                      : ""
                  }
                  ngcLastName={
                    this.state.Registration.ngcContainerRegistry
                      ? this.state.Registration.ngcContainerRegistry.lastName
                      : ""
                  }
                  ngcShortCompanyName={
                    this.state.Registration.ngcContainerRegistry
                      ? this.state.Registration.ngcContainerRegistry
                          .shortCompanyName
                      : ""
                  }
                ></UserDetailsView>
              </Collapsible>
            </div>
            <h3 className='details-section-header'>Organization info </h3>
            {/* {assignView && canCreateCust && (
              <h3 className='details-section-header'>Organization info </h3>
            )} */}
            {assignView && canCreateCust && (
              <button
                className='btn inline'
                disabled={!matchesLoaded}
                onClick={() => {
                  let ao = {
                    Name: this.state.Registration.companyDetail.companyName,
                    Street1: this.state.Registration.companyDetail.street1,
                    Street2: this.state.Registration.companyDetail.street2,
                    City: this.state.Registration.companyDetail.city,
                    Country: this.state.Registration.companyCountryName,
                    CountryCode: this.state.Registration.companyCountryCode,
                    State: this.state.Registration.companyStateName,
                    Zip: this.state.Registration.companyDetail.zipCode,
                    SFDCID: "",
                  };
                  this.setState({
                    AdminOverride: !adminOverride,
                    AdminOverrideData: ao,
                  });
                }}
              >
                {adminOverride && (
                  <div>
                    <FontAwesomeIcon icon='undo' /> Cancel
                  </div>
                )}
                {!adminOverride && (
                  <div>
                    <FontAwesomeIcon icon='edit' /> Edit
                  </div>
                )}
              </button>
            )}
            <div className='company-details'>
              <div>
                {!adminOverride && r.companyDetail.companyName
                  ? r.companyDetail.companyName
                  : ""}
                {adminOverride && (
                  <label>
                    Name:{" "}
                    <input
                      type='text'
                      value={this.state.AdminOverrideData.Name}
                      onChange={this.handleNameChange}
                    />
                  </label>
                )}
              </div>
              <div>
                {!adminOverride && r.companyCountryName
                  ? r.companyCountryName
                  : ""}
                {adminOverride && (
                  <Select
                    labelText={"Country"}
                    optionValues={this.state.Countries}
                    value={this.state.OrgData.CompanyCountry}
                    style={styles}
                    innerStyle={innerStyles}
                    onChange={this.handleCompanyCountryChange}
                    displayFieldName='countryName'
                    valueFieldName='id'
                  />
                )}
              </div>
              <div>
                {!adminOverride && r.stateName ? ", " + r.stateName : ""}{" "}
                {adminOverride && (
                  <Select
                    labelText={"State"}
                    optionValues={this.state.States}
                    value={this.state.OrgData.CompanyState}
                    style={styles}
                    innerStyle={innerStyle2}
                    onChange={this.handleCompanyStateChange}
                    displayFieldName='stateName'
                                    valueFieldName='id'
                                    className="state"
                  />
                )}
              </div>

              <div>
                {!adminOverride && r.companyDetail.city
                  ? r.companyDetail.city
                  : ""}
                {adminOverride && (
                  <label>
                    City:{" "}
                    <input
                      type='text'
                      value={adminOverrideData.City}
                      onChange={this.handleCityChange}
                    />
                  </label>
                )}
              </div>

              <div>
                {!adminOverride && r.companyDetail.street1
                  ? r.companyDetail.street1
                  : ""}
                {!adminOverride && r.companyDetail.street2
                  ? ", " + r.companyDetail.street2
                  : ""}
                {adminOverride && (
                  <div>
                    <div>
                      <label>
                        Street1:{" "}
                        <input
                          type='text'
                          value={adminOverrideData.Street1}
                          onChange={this.handleStreet1Change}
                        />
                      </label>
                    </div>
                    <div>
                      <label>
                        Street2:{" "}
                        <input
                          type='text'
                          value={adminOverrideData.Street2}
                          onChange={this.handleStreet2Change}
                        />
                      </label>
                    </div>
                  </div>
                )}
              </div>
              <div>
                {!adminOverride && r.companyDetail.zipCode
                  ? r.companyDetail.zipCode
                  : ""}
                {adminOverride && (
                  <div>
                    <div>
                      <label>
                        Zip:{" "}
                        <input
                          type='text'
                          value={adminOverrideData.Zip}
                          onChange={this.handleZipChange}
                        />
                      </label>
                    </div>
                   
                    {/* {adminOverride && canCreateCust && (
                      <div className='detail-actions'>
                        <button
                          className='btn primary'
                          onClick={this.handleCreateAccountClick}
                        >
                          Create New Account
                        </button>
                      </div>
                    )} */}
                  </div>
                )}
              </div>
              <div>
                
                {adminOverride && (
                  <div>
                    <div>
                      <label>
                        Website:{" "}
                        <input
                          type='text'
                          value={adminOverrideData.Website}
                          onChange={this.handleWebsiteChange}
                        />
                      </label>
                    </div>
                   <div>
                   {adminOverride && (
                  <Select
                    labelText={"Hierarchy"}
                    optionValues={this.state.AccountHierarchy}
                    value={this.state.AdminOverrideData.AccountHierarchy}
                    style={styles}
                    innerStyle={innerStyles}
                    onChange={this.handleAccountTypeChange}
                    displayFieldName='accountName'
                    valueFieldName='accountID'
                  />
                )}
                   </div>
                    {adminOverride && canCreateCust && (
                      <div className='detail-actions'>
                        <button
                          className='btn primary'
                          onClick={this.handleCreateAccountClick}
                        >
                          Create New Account
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          {assignView && (
            <div>
              {canViewCustAssign && (
                <div>
                  <div>
                    <div>
                      {" "}
                      <h2 className='details-section-header'>Search: </h2>{" "}
                    </div>
                    <div>
                      <label style={topMargin20}>
                        SFDC ID:{" "}
                        <input
                          type='text'
                          value={adminOverrideData.SDFCID}
                          onChange={this.handleSFDCChange}
                        />
                      </label>
                    </div>
                  </div>

                  <div className='matches-filter'>
                    <button
                      className='btn'
                      onClick={this.handleRefreshClick}
                      disabled={!matchesLoaded}
                    >
                      Refresh Matching
                    </button>
                    <span> Max</span>
                    <input
                      className='input'
                      type='number'
                      style={styles}
                      value={showMatchingCount}
                      onChange={this.handleMatchingCountChange}
                      min='1'
                    />
                    <span>matches</span>
                  </div>
                  <div>
                    {matchesLoaded && matches.length > 0 && (
                      <div className='matches'>
                        <div className='match-row'>
                          <div>SFDC ID</div>
                          <div>SAP ID</div>
                          <div>Account Name</div>
                          <div>Street</div>
                          <div>City</div>
                          <div>Country</div>
                          <div>Match%</div>
                          <div>Action</div>
                        </div>
                        {matchesList}
                      </div>
                    )}
                    {matchesLoaded && matches.length === 0 && (
                      <div>No matches found</div>
                    )}
                    {!matchesLoaded && (
                      <div className='margin-width'>
                        <FontAwesomeIcon
                          icon='spinner'
                          spin
                          className='color-nvgreen'
                        />{" "}
                        Looking for matches
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
          {!assignView && (
            <div>
              {!statusLoaded && (
                <div className='margin-height'>
                  <FontAwesomeIcon
                    icon='spinner'
                    spin
                    className='color-nvgreen'
                  />{" "}
                  Determining the orchestration status for this registration...
                </div>
              )}
              {statusLoaded && (
                <div>
                  <h3 className='details-section-header iblock'>
                    Orchestration status:{" "}
                    <span
                      className={
                        os.Status === "Failed" ? "color-error" : "color-nvgreen"
                      }
                    >
                      {os.Status}
                    </span>
                  </h3>

                  <span className='customerrormsgfont'>
                    {r.licenseTypeId === 1 &&
                      r.requestStatusId === 7 &&
                      r.orchestrationId === null &&
                      r.orchestrationStatus === null &&
                      " : " + r.statusDescription}
                  </span>

                  {showRerun && canProcessReg && allowRerun && !isReqCancelled && (
                    <button
                      className='btn inline bg-color-nvgreen'
                      onClick={this.handleReRunClick}
                      title='Click to restart the orchestration process'
                    >
                      Rerun Orchestration
                    </button>
                  )}

                  {showRerun && canProcessReg && !allowRerun && (
                    <div>The orchestration process will restart.</div>
                  )}
                  <div className='sub-status'>
                    {os.Message.Unk && (
                      <div
                        className={
                          os.Message.Unk.Status === "N/A" ||
                          os.Message.Unk.Status === "Not Run"
                            ? "color-neutral"
                            : ""
                        }
                      >
                        <div>Unknown</div>
                        <div
                          className={
                            os.Message.Unk.Status === "N/A" ||
                            os.Message.Unk.Status === "Not Run"
                              ? "color-neutral"
                              : os.Message.Unk.Status === "Success"
                              ? "color-nvgreen"
                              : "color-error"
                          }
                        >
                          {os.Message.Unk.Status === "Not Run"
                            ? "N/A"
                            : os.Message.Unk.Status}
                        </div>
                        <div>{os.Message.Unk.Description}</div>
                      </div>
                    )}
                    {os.Message.Marketo && (
                      <div
                        className={
                          os.Message.Marketo.Status === "N/A" ||
                          os.Message.Marketo.Status === "Not Run"
                            ? "color-neutral"
                            : ""
                        }
                      >
                        <div>Marketo</div>
                        <div
                          className={
                            os.Message.Marketo.Status === "N/A" ||
                            os.Message.Marketo.Status === "Not Run"
                              ? "color-neutral"
                              : os.Message.Marketo.Status === "Success"
                              ? "color-nvgreen"
                              : "color-error"
                          }
                        >
                          {os.Message.Marketo.Status === "Not Run"
                            ? "N/A"
                            : os.Message.Marketo.Status}
                        </div>
                        <div>{os.Message.Marketo.Description}</div>
                      </div>
                    )}
                      {os.Message.SFDCEval && (
                      <div
                        className={
                          os.Message.SFDCEval.Status === "N/A" ||
                          os.Message.SFDCEval.Status === "Not Run"
                            ? "color-neutral"
                            : ""
                        }

                      >
                        <div>SFDCEval</div>
                        <div
                          className={
                            os.Message.SFDCEval.Status === "N/A" ||
                            os.Message.SFDCEval.Status === "Not Run"
                              ? "color-neutral"
                              : os.Message.SFDCEval.Status === "Success"
                              ? "color-nvgreen"
                              : "color-error"
                          }
                        >
                          {os.Message.SFDCEval.Status === "Not Run"
                            ? "N/A"
                            : os.Message.SFDCEval.Status}
                        </div>
                        <div>{os.Message.SFDCEval.Description}</div>
                      </div>
                    )}
                    {os.Message.SFDC && (
                      <div
                        className={
                          os.Message.SFDC.Status === "N/A" ||
                          os.Message.SFDC.Status === "Not Run"
                            ? "color-neutral"
                            : ""
                        }
                      >
                        <div>SFDC</div>
                        <div
                          className={
                            os.Message.SFDC.Status === "N/A" ||
                            os.Message.SFDC.Status === "Not Run"
                              ? "color-neutral"
                              : os.Message.SFDC.Status === "Success" ||
                                os.Message.SFDC.Status === "SUCCESS"
                              ? "color-nvgreen"
                              : "color-error"
                          }
                        >
                          {os.Message.SFDC.Status === "Not Run"
                            ? "N/A"
                            : os.Message.SFDC.Status}
                        </div>
                        <div>{os.Message.SFDC.Description}</div>
                      </div>
                    )}

                    {os.Message.SCResponse && (
                      <div
                        className={
                          os.Message.SCResponse.Status === "N/A" ||
                          os.Message.SCResponse.Status === "Not Run"
                            ? "color-neutral"
                            : ""
                        }
                      >
                        <div>SC</div>
                        <div
                          className={
                            os.Message.SCResponse.Status === "N/A" ||
                            os.Message.SCResponse.Status === "Not Run"
                              ? "color-neutral"
                              : os.Message.SCResponse.Status === "Success"
                              ? "color-nvgreen"
                              : "color-error"
                          }
                        >
                          {os.Message.SCResponse.Status === "Not Run"
                            ? "N/A"
                            : os.Message.SCResponse.Status}
                        </div>
                        <div>{os.Message.SCResponse.Description}</div>
                      </div>
                    )}

                    {os.Message.NGCCreateOrgResponse && (
                      <div
                        className={
                          os.Message.NGCCreateOrgResponse.Status === "N/A" ||
                          os.Message.NGCCreateOrgResponse.Status === "Not Run"
                            ? "color-neutral"
                            : ""
                        }
                      >
                        <div>NGC Provisioning</div>
                        <div
                          className={
                            os.Message.NGCCreateOrgResponse.Status === "N/A" ||
                            os.Message.NGCCreateOrgResponse.Status === "Not Run"
                              ? "color-neutral"
                              : os.Message.NGCCreateOrgResponse.Status === "Success"
                              ? "color-nvgreen"
                              : "color-error"
                          }
                        >
                          {os.Message.NGCCreateOrgResponse.Status === "Not Run"
                            ? "N/A"
                            : os.Message.NGCCreateOrgResponse.Status}
                        </div>
                        <div>{os.Message.NGCCreateOrgResponse.Description}</div>
                      </div>
                    )}
                    
                    {os.Message.NGCResponse && (
                      <div
                        className={
                          os.Message.NGCResponse.Status === "N/A" ||
                          os.Message.NGCResponse.Status === "Not Run"
                            ? "color-neutral"
                            : ""
                        }
                      >
                        <div>NGC Container Report</div>
                        <div
                          className={
                            os.Message.NGCResponse.Status === "N/A" ||
                            os.Message.NGCResponse.Status === "Not Run"
                              ? "color-neutral"
                              : os.Message.NGCResponse.Status === "Success"
                              ? "color-nvgreen"
                              : "color-error"
                          }
                        >
                          {os.Message.NGCResponse.Status === "Not Run"
                            ? "N/A"
                            : os.Message.NGCResponse.Status}
                        </div>
                        <div>{os.Message.NGCResponse.Description}</div>
                      </div>
                    )}

                    {os.Message.EMS && (
                      <div
                        className={
                          os.Message.EMS.Status === "N/A" ||
                          os.Message.EMS.Status === "Not Run"
                            ? "color-neutral"
                            : ""
                        }
                      >
                        <div>EMS</div>
                        <div
                          className={
                            os.Message.EMS.Status === "N/A" ||
                            os.Message.EMS.Status === "Not Run"
                              ? "color-neutral"
                              : os.Message.EMS.Status === "Success"
                              ? "color-nvgreen"
                              : "color-error"
                          }
                        >
                          {os.Message.EMS.Status === "Not Run"
                            ? "N/A"
                            : os.Message.EMS.Status}
                        </div>
                        <div>{os.Message.EMS.Description}</div>
                      </div>
                    )}


{os.Message.NGCCreateOrgResponseEval && (
                      <div
                        className={
                          os.Message.NGCCreateOrgResponseEval.Status === "N/A" ||
                          os.Message.NGCCreateOrgResponseEval.Status === "Not Run"
                            ? "color-neutral"
                            : ""
                        }
                      >
                        <div>NGC Create Org</div>
                        <div
                          className={
                            os.Message.NGCCreateOrgResponseEval.Status === "N/A" ||
                            os.Message.NGCCreateOrgResponseEval.Status === "Not Run"
                              ? "color-neutral"
                              : os.Message.NGCCreateOrgResponseEval.Status === "Success"
                              ? "color-nvgreen"
                              : "color-error"
                          }
                        >
                          {os.Message.NGCCreateOrgResponseEval.Status === "Not Run"
                            ? "N/A"
                            : os.Message.NGCCreateOrgResponseEval.Status}
                        </div>
                        <div>{os.Message.NGCCreateOrgResponseEval.Description}</div>
                      </div>
                    )}
{os.Message.ADDEnablementEval && (
                      <div
                        className={
                          os.Message.ADDEnablementEval.Status === "N/A" ||
                          os.Message.ADDEnablementEval.Status === "Not Run"
                            ? "color-neutral"
                            : ""
                        }
                      >
                        <div>NGC Add Enablement</div>
                        <div
                          className={
                            os.Message.ADDEnablementEval.Status === "N/A" ||
                            os.Message.ADDEnablementEval.Status === "Not Run"
                              ? "color-neutral"
                              : os.Message.ADDEnablementEval.Status === "Success"
                              ? "color-nvgreen"
                              : "color-error"
                          }
                        >
                          {os.Message.ADDEnablementEval.Status === "Not Run"
                            ? "N/A"
                            : os.Message.ADDEnablementEval.Status}
                        </div>
                        <div>{os.Message.ADDEnablementEval.Description}</div>
                      </div>
                    )}


                    {os.Message.IDM && (
                      <div
                        className={
                          os.Message.IDM.Status === "N/A" ||
                          os.Message.IDM.Status === "Not Run"
                            ? "color-neutral"
                            : ""
                        }
                      >
                        <div>NLP</div>
                        <div
                          className={
                            os.Message.IDM.Status === "N/A" ||
                            os.Message.IDM.Status === "Not Run"
                              ? "color-neutral"
                              : os.Message.IDM.Status === "Success"
                              ? "color-nvgreen"
                              : "color-error"
                          }
                        >
                          {os.Message.IDM.Status === "Not Run"
                            ? "N/A"
                            : os.Message.IDM.Status}
                        </div>
                        <div>{os.Message.IDM.Description}</div>
                      </div>
                    )}





{os.Message.AppHUB && (
                      <div
                        className={
                          os.Message.AppHUB.Status === "N/A" ||
                          os.Message.AppHUB.Status === "Not Run"
                            ? "color-neutral"
                            : ""
                        }
                      >
                        <div>AppHUB</div>
                        <div
                          className={
                            os.Message.AppHUB.Status === "N/A" ||
                            os.Message.AppHUB.Status === "Not Run"
                              ? "color-neutral"
                              : os.Message.AppHUB.Status === "Success"
                              ? "color-nvgreen"
                              : "color-error"
                          }
                        >
                          {os.Message.AppHUB.Status === "Not Run"
                            ? "N/A"
                            : os.Message.AppHUB.Status}
                        </div>
                        <div>{os.Message.AppHUB.Description}</div>
                      </div>
                    )}










                    {os.Message.LGS && (
                      <div
                        className={
                          os.Message.LGS.Status === "N/A" ||
                          os.Message.LGS.Status === "Not Run"
                            ? "color-neutral"
                            : ""
                        }
                      >
                        <div>LGS</div>
                        <div
                          className={
                            os.Message.LGS.Status === "N/A" ||
                            os.Message.LGS.Status === "Not Run"
                              ? "color-neutral"
                              : os.Message.LGS.Status === "Success"
                              ? "color-nvgreen"
                              : "color-error"
                          }
                        >
                          {os.Message.LGS.Status === "Not Run"
                            ? "N/A"
                            : os.Message.LGS.Status}
                        </div>
                        <div>{os.Message.LGS.Description}</div>
                      </div>
                    )}
                    
                    {os.Message.EMSCommercial && (
                      <div
                        className={
                          os.Message.EMSCommercial.Status === "N/A" ||
                          os.Message.EMSCommercial.Status === "Not Run"
                            ? "color-neutral"
                            : ""
                        }
                      >
                        <div>Entitlement</div>
                        <div
                          className={
                            os.Message.EMSCommercial.Status === "N/A" ||
                            os.Message.EMSCommercial.Status === "Not Run"
                              ? "color-neutral"
                              : os.Message.EMSCommercial.Status === "Success"
                              ? "color-nvgreen"
                              : "color-error"
                          }
                        >
                          {os.Message.EMSCommercial.Status === "Not Run"
                            ? "N/A"
                            : os.Message.EMSCommercial.Status}
                        </div>
                        <div>{os.Message.EMSCommercial.Description}</div>
                      </div>
                    )}
                     
                     {os.Message.UpdateOrgCommercial && (
                     
                      <div
                        className={
                          os.Message.UpdateOrgCommercial.Status === "N/A" ||
                          os.Message.UpdateOrgCommercial.Status === "Not Run"
                            ? "color-neutral"
                            : ""
                        }
                      >
                        <div>UpdateNGC Org</div>
                        <div
                          className={
                            os.Message.UpdateOrgCommercial.Status === "N/A" ||
                            os.Message.UpdateOrgCommercial.Status === "Not Run"
                              ? "color-neutral"
                              : os.Message.UpdateOrgCommercial.Status === "Success"
                              ? "color-nvgreen"
                              : "color-error"
                          }
                        >
                          {os.Message.UpdateOrgCommercial.Status === "Not Run"
                            ? "N/A"
                            : os.Message.UpdateOrgCommercial.Status}
                        </div>
                        <div>{os.Message.UpdateOrgCommercial.Description}</div>
                      </div>
                    )}
                       {os.Message.ADDEnablement && (
                      <div
                        className={
                          os.Message.ADDEnablement.Status === "N/A" ||
                          os.Message.ADDEnablement.Status === "Not Run"
                            ? "color-neutral"
                            : ""
                        }
                      >
                        <div>AddNGC Roles</div>
                        <div
                          className={
                            os.Message.ADDEnablement.Status === "N/A" ||
                            os.Message.ADDEnablement.Status === "Not Run"
                              ? "color-neutral"
                              : os.Message.ADDEnablement.Status === "Success"
                              ? "color-nvgreen"
                              : "color-error"
                          }
                        >
                          {os.Message.ADDEnablement.Status === "Not Run"
                            ? "N/A"
                            : os.Message.ADDEnablement.Status}
                        </div>
                        <div>{os.Message.ADDEnablement.Description}</div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }

  handlePECIDUpdate = (e) => {
    this.setState({
      isPecIDEditable: true,
      origPECIDBeforeUpdate: this.state.Registration.pecid,
    });
  };
  handleSaveForPECID = (e) => {
    this.setState({ isPecIDEditable: false });
    let data = {};

    if (
      !(
        this.state.origPECIDBeforeUpdate === this.state.Registration.pecid ||
        this.state.Registration.pecid.length === 0
      )
    ) {
      this.setState({ StatusLoaded: false });
      data = {
        RegID: this.state.Registration.id,
        PECID: this.state.Registration.pecid,
        UserName: "",
      };

      adalApiFetch(axios, api.POST_UPDATE_PECID, {
        method: "post",
        data: data,
      })
        .then((response) => {
          console.log(response);
          if (response.data > 0) {
            toastSuccess("PEC ID updated successfully");

            this.getStatus(this.state.Registration.id);
          }

          // setTimeout(() => {
          //   this.props.history.push("/");
          // }, 1500);
        })
        .catch((error) => {
       
          console.log(error);
          if (error.response) {
            console.log(error.response);
            toastError(error.response.data["Message"]);
            this.setState({ StatusLoaded: true });
          }
        });
    } else {
      let roo = Object.assign({}, this.state.Registration);

      roo.pecid = this.state.origPECIDBeforeUpdate;
      this.setState({ Registration: roo, isPecIDEditable: false });
    }
  };

  handleCancelForPECID = (e) => {
    let roo = Object.assign({}, this.state.Registration);

    roo.pecid = this.state.origPECIDBeforeUpdate;
    this.setState({ Registration: roo, isPecIDEditable: false });
  };

  handlePECIDChange = (e) => {
    let ro = Object.assign({}, this.state.Registration);
    ro.pecid = e.target.value;
    this.setState({ Registration: ro });
  };
  handleCompanyCountryChange = (e) => {
    const val = e.target.value;
    let orgData = Object.assign({}, this.state.OrgData);
    orgData.CompanyCountry = parseInt(val);
    this.getStatesByCountry(val);

    const selected = this.state.Countries.filter(
      (countries) => countries.id === parseInt(val)
    );
    const countryCode = selected[0].id === 0 ? "" : selected[0].code;
    this.setState({ OrgData: orgData, SelectedCountryCode: countryCode });
    let ao = Object.assign({}, this.state.AdminOverrideData);
    ao.Country = selected[0].id === 0 ? "" : selected[0].countryName;
    ao.CountryCode = selected[0].id === 0 ? "" : countryCode;
    ao.State = "";
    this.setState({ AdminOverrideData: ao });
  };
  handleCompanyStateChange = (e) => {
    const val = e.target.value;
    let orgData = Object.assign({}, this.state.OrgData);
    orgData.CompanyState = parseInt(val);
    this.setState({ OrgData: orgData });
    const selectedState = this.state.States.filter(
      (states) => states.id === parseInt(val)
    );
    let ao = Object.assign({}, this.state.AdminOverrideData);
    ao.State = selectedState[0].id === 0 ? "" : selectedState[0].stateName;
    this.setState({ AdminOverrideData: ao });
  };

  handleAssignClick(pecID, custName, sapID, country) {
    const assignDTO = {
      regID: parseInt(this.state.Registration.id),
      sfdcID: pecID,
      sapID: sapID,
      accountName: custName,
      country: country,
    };

    adalApiFetch(axios, api.POST_ASSIGN_CUSTOMER, {
      method: "post",
      data: assignDTO,
      cancelToken: this.source.token,
    })
      .then((response) => {
        console.log(response);
        toastSuccess(response.data["Message"]);
        setTimeout(() => {
          if(this.props.ispopUp)
          {
            this.props.refreshParentPop();
       
          }
          else{
            this.props.history.push("/");
          }
         
        }, 1500);
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          console.log(error.response);
          toastError(error.response.data["Message"]);
        }
      });
  }

  handleNameChange = (e) => {
    let ao = Object.assign({}, this.state.AdminOverrideData);
    ao.Name = e.target.value;
    this.setState({ AdminOverrideData: ao });
  };
  handleStreet1Change = (e) => {
    let ao = Object.assign({}, this.state.AdminOverrideData);
    ao.Street1 = e.target.value;
    this.setState({ AdminOverrideData: ao });
  };
  handleStreet2Change = (e) => {
    let ao = Object.assign({}, this.state.AdminOverrideData);
    ao.Street2 = e.target.value;
    this.setState({ AdminOverrideData: ao });
  };
  handleCityChange = (e) => {
    let ao = Object.assign({}, this.state.AdminOverrideData);
    ao.City = e.target.value;
    this.setState({ AdminOverrideData: ao });
  };
  /* handleStateChange = e => {
    let ao = Object.assign({}, this.state.AdminOverrideData);
    ao.State = e.target.value;
    this.setState({ AdminOverrideData: ao });
  };
  handleCountryChange = e => {
    let ao = Object.assign({}, this.state.AdminOverrideData);
      ao.Country = e.target.value;
      console.log(ao.Country);
    this.setState({ AdminOverrideData: ao });
  }; */
  handleZipChange = (e) => {
    let ao = Object.assign({}, this.state.AdminOverrideData);
    ao.Zip = e.target.value;
    this.setState({ AdminOverrideData: ao });
  };
  handleWebsiteChange = (e) => {
    let ao = Object.assign({}, this.state.AdminOverrideData);
    ao.Website = e.target.value;
    this.setState({ AdminOverrideData: ao });
  };
  handleAccountTypeChange = (e) => {
    let ao = Object.assign({}, this.state.AdminOverrideData);
    const selectedHierarchy = this.state.AccountHierarchy.filter(
      (account) => account.accountID === parseInt( e.target.value)
    );
    ao.AccountHierarchy =selectedHierarchy[0].accountID;// e.target.value;
    ao.AccountHierarchyName =selectedHierarchy[0].accountName;// e.target.value;
  // ao.AccountHierarchy =selectedHierarchy[0];// e.target.value;
    this.setState({ AdminOverrideData: ao });
  };
  handleSFDCChange = (e) => {
    let ao = Object.assign({}, this.state.AdminOverrideData);
    ao.SDFCID = e.target.value;
    this.setState({ AdminOverrideData: ao });
  };

  handleCreateAccountClick = (e) => {
    let data = {};
    if (this.state.AdminOverride) {
      data = {
        Name: this.state.AdminOverrideData.Name,
        Street1: this.state.AdminOverrideData.Street1,
        Street2: this.state.AdminOverrideData.Street2,
        City: this.state.AdminOverrideData.City,
        Country: this.state.AdminOverrideData.Country,
        CountryCode: this.state.AdminOverrideData.CountryCode,
        State: this.state.AdminOverrideData.State,
        Zip: this.state.AdminOverrideData.Zip,
        RegistrationID: this.state.Registration.id,
        Website:this.state.AdminOverrideData.Website,
        AccountHierarchy:this.state.AdminOverrideData.AccountHierarchyName

      };
    } else {
      data = {
        Name: this.state.Registration.companyDetail.companyName,
        Street1: this.state.Registration.companyDetail.street1,
        Street2: this.state.Registration.companyDetail.street2,
        City: this.state.Registration.companyDetail.city,
        Country: this.state.Registration.companyCountryName,
        CountryCode: this.state.Registration.companyDetail.CompanyCountryCode,
        State: this.state.Registration.companyStateName,
        Zip: this.state.Registration.companyDetail.zipCode,
        RegistrationID: this.state.Registration.id,
      };
    }

    console.log(data);

    adalApiFetch(axios, api.POST_CREATE_CUSTOMER, {
      method: "post",
      data: data,
      cancelToken: this.source.token,
    })
      .then((response) => {
        console.log(response);
        //this.setState({ Matches: response.data, MatchesLoaded: true });
        toastSuccess(response.data);
        setTimeout(() => {
          if(this.props.ispopUp)
          {
            this.props.refreshParentPop();
            
            
          }
          else{
            this.props.history.push("/");
          }
         
        }, 1500);
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          console.log(error.response);
          toastError(error.response.data["Message"]);
        }
      });
  };

  handleRefreshClick = (e) => {
    this.getMatches();
  };

  getMatches = (e) => {
    const { canViewCustAssign } = this.props;

    if (!canViewCustAssign) {
      return;
    }
    this.setState({ MatchesLoaded: false });
    const useAlt = this.state.AdminOverride;

    const data = {
      zsD_CONTROL_PARAMETERS: {
        display_count: this.state.ShowMatchingCount,
      },
      zsD_SEARCH_BY_ADDRESS: {
        name1: useAlt
          ? this.state.AdminOverrideData.Name
          : this.state.Registration.companyDetail.companyName,
        street1: useAlt
          ? this.state.AdminOverrideData.Street1
          : this.state.Registration.companyDetail.street1,
        street2: useAlt
          ? this.state.AdminOverrideData.Street2
          : this.state.Registration.companyDetail.street2,
        city: useAlt
          ? this.state.AdminOverrideData.City
          : this.state.Registration.companyDetail.city,
        state: useAlt
          ? this.state.AdminOverrideData.State
          : this.state.Registration.companyStateName,
        country: useAlt
          ? this.state.AdminOverrideData.Country
          : this.state.Registration.companyCountryName,
        zipcode: useAlt
          ? this.state.AdminOverrideData.Zip
          : this.state.Registration.companyDetail.zipCode,
      },
      zsD_SEARCH_BY_CONTACT: {
        email: this.state.Registration.emailID,
      },
      zSD_SEARCH_BY_SFDCID: {
        zsfdcID: this.state.AdminOverrideData.SDFCID,
      },
    };

    adalApiFetch(axios, api.GET_MATCHES, {
      method: "post",
      data: data,
      cancelToken: this.source.token,
    })
      .then((response) => {
        console.log(response.data);
        this.setState({ Matches: response.data, MatchesLoaded: true });
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          console.log(error.response);
          toastError(error.response.data);
        }
      });
  };

  handleMatchingCountChange = (e) => {
    let val = parseInt(e.target.value);
    if (isNaN(val)) {
      val = 5;
    }
    this.setState({ ShowMatchingCount: val });
  };
  handleCancelClick = (e) => {
    let val = parseInt(e.target.value);
  };

  handleReRunClick = (e) => {
    let orchestrationId = this.state.Registration.orchestrationId;

    adalApiFetch(axios, api.RE_RUN_ORCHESTRATION + orchestrationId, {
      method: "post",
      cancelToken: this.source.token,
    })
      .then((response) => {
        this.setState({ AllowRerun: false });
        console.log(response.data);
        toastSuccess("Orchestration Rerun Triggered");
      })
      .catch((error) => {
        console.log(error.response);
        toastError(error.response.data);
      });
  };

  handleBackClick = (e) => {
    this.props.history.goBack();
  };

  getStatus(regId) {
    let regID = regId ? regId : this.state.Registration.id;

    adalApiFetch(axios, api.GET_STATUS + regID, {
      cancelToken: this.source.token,
    })
      .then((response) => {
        console.log(response);

        if (response.data) {
          console.log(response.data.Status);

          if (response.data.Status === "Running") {
            this.setState({
              StatusLoaded: false,
            });
            setTimeout(() => {
              this.getStatus(regId);
            }, 5000);

            //;this.getStatus(regId) //to continously check unless Status is != Running
          } else {
            this.setState({
              OrchStatus: response.data,
              StatusLoaded: true,
            });
          }
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          console.log(error.response);

          if (error.response.data && error.response.data.Message) {
            // this.setState({
            //   OrchStatus: this.defaultOrchStatus,
            //   StatusLoaded: true
            // });
            const errMsg = error.response.data.Message;
            this.setState({
              StatusLoaded: true,
              OrchStatus: {
                Status: "Failed",
                Message: { Unk: { Status: "Unknown", Description: errMsg } },
              },
            });
          } else if (error.response.data && error.response.data.title) {
            // this.setState({
            //   OrchStatus: this.defaultOrchStatus,
            //   StatusLoaded: true
            // });
            this.setState({
              StatusLoaded: true,
              OrchStatus: {
                Status: "Failed",
                Message: {
                  Unk: { Status: "Unknown", Description: "Unknown error" },
                },
              },
            });
          }
        }
        if (error.response && error.response.status === 400) {
          // toastError("Please view in couple of minutes");
          const errMsg = error.response.data.Message;
          this.setState({
            StatusLoaded: true,
            OrchStatus: {
              Status: "Not Started",
              Message: {
                Unk: { Status: "Unknown", Description: errMsg },
              },
            },
          });
        } else {
          toastError(error.message ? error.message : error.response.data);
        }
      });
  }

  getReg() {
    const id = this.props.match.params.id === undefined ? this.props.matchparamsid : this.props.match.params.id;
    adalApiFetch(axios, api.GET_REGISTRATIONS + id, {
      cancelToken: this.source.token,
    })
      .then((response) => {
        //debugger;
        const isErrorType = response.data.requestStatusId === 7;
        const isCancelledType = response.data.requestStatusId === 9;
        const missingCompanyInfo = response.data.companyDetail === null;
        let orgData = Object.assign({}, this.state.OrgData);

        if (missingCompanyInfo) {
          response.data.companyDetail = {};
        } else {
          const countryID = response.data.companyDetail.countryId;
          const stateID = response.data.companyDetail.stateId;
          if (countryID) {
            this.getStatesByCountry(countryID);
          }
          orgData.CompanyCountry = countryID;
          orgData.CompanyState = stateID;
        }
        if (this.state.Registration.requestStatusName === "PCUST") {
          this.setState({
            IsPCUST: true,
          });
        }

        this.setState({
          Registration: response.data,
          ShowRerun: isErrorType,
          OrgData: orgData,
          isCancelledTypeReq: isCancelledType,
          MatchesLoaded:true
        });
        if (this.state.AssignView) {
     //     this.getMatches();
        } else {
          //this.getStatus(response.data.orchestrationId);
          this.getStatus(response.data.id);
          //console.log(response.data.orchestrationId);
        }
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
        toastError(error.message ? error.message : error.response.data);
      });
  }

  getCountryData() {
    adalApiFetch(axios, api.GET_COUNTRIES, {
      cancelToken: this.source.token,
    })
      .then((response) => {
        let d = response.data;
        d.unshift(this.defaultCountryOption);
        this.setState({
          Countries: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        toastError(error.message ? error.message : error.response.data);
      });
  }

  getStatesByCountry = async (id, useAlt) => {
    let selected = this.state.Countries.filter(
      (country) => parseInt(country.id) === parseInt(id)
    );

    let countryCode = selected[0].code;
    adalApiFetch(axios, api.GET_STATE_FROM_COUNTRY + countryCode, {})
      .then((response) => {
        if (useAlt) {
          if (isArray(response.data) && response.data.length > 0) {
            this.setState({ AltState: parseInt(response.data[0].id) });
          }
          this.setState({ AltStates: response.data, Messages: [] });
        } else {
          /*if (isArray(response.data) && response.data.length > 0) {
                    let orgData = Object.assign({}, this.state.OrgData)
                    orgData.CompanyState = parseInt(response.data[0].id)
                    this.setState({ OrgData: orgData })
                }*/
          let d = response.data;
          d.unshift(this.defaultStateOption);
          this.setState({ States: response.data, Messages: [] });
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          if (useAlt) {
            this.setState({
              AltStates: [{ id: -1, code: "", stateName: "N/A" }],
              AltState: -1,
            });
          } else {
            let orgData = Object.assign({}, this.state.OrgData);
            orgData.CompanyState = -1;
            this.setState({
              OrgData: orgData,
              States: [{ id: -1, code: "", stateName: "N/A" }],
            });
          }
        } else {
          this.setState({
            Messages: ["Something went wrong!"],
            MessageIcon: false,
            States: [this.defaultStateOption],
          });
        }
      });
  };
}

export default RegistrationDetail;
