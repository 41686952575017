import React from "react";
import Input from "../../util/Input";
import { PropTypes } from "prop-types";
import Select from "../../util/Select";

const LicenseTypeInfo = props => {


  return (
    <div className="AppSection">
 
       <div className="leftColumn margin-height">
            <Input
              labelText={"License Type Name"}
              className={"margin-height lblNVGreen required"}
              inputClassName={"margin-left2"}
              isRequired={true}
              autoFocus={props.autoFocusName}
              value={props.orgData.LicTypeName}
              onChange={props.handleLicenseTypeName}
           />
        
              <Select
                  labelText={"License Category"}
                  className={"margin-height lblNVGreen required"}
                  inputClassName={"selectWidth2"}
                  optionValues={props.licTypeCategories}
                  isRequired={true}
                  value={props.orgData.LicTypeCategoryID}
                  onChange={props.handleLicenseTypeCategoryChange}
                  displayFieldName="value"
                  valueFieldName="key"
              />

            <div className="flex-fill margin-height">
                 <center><button className="btn" onClick={props.onSaveClick} disabled={!props.dataFilled} > Save  </button> </center>
            </div>
        </div>
    </div>
  );
};

LicenseTypeInfo.propTypes = {
  isRequired: PropTypes.bool,
  autoFocusName: PropTypes.bool,
  autoFocusDescr: PropTypes.bool,
  orgData: PropTypes.object,
  handleLicenseTypeName: PropTypes.func.isRequired,
  handleLicenseTypeCategoryChange: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func,
  dataFilled: PropTypes.bool
};

export default LicenseTypeInfo;
