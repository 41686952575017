import React, { Component } from "react";
import axios from "axios";
import * as api from "../../constants/api";
import { adalApiFetch } from "../../adalConfig";
import ReactTable from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-table/react-table.css";
import "react-datepicker/dist/react-datepicker.css";
import RWAMappingInfo from "../rwamapping/RWAMappingInfo";
import Modal from 'react-modal';
import { toastSuccess, toastError, customStyles } from '../../util/CommonFunctions'
import NavMenu from '../NavMenu'


Modal.setAppElement('#root')

class RWAMappingList extends Component {
  constructor(props) {
    super(props);
  
    this.state = {
      defaultOption: { key: 0, value: "All" },
      RWAMappings: [],
      DisplayedCount: 0,
      Count: 0,
      Take: 10,
      ShowFilters: true,
      autofocusName: true,
      autofocusDescr: false,
      importBomInfo:false,
      Filters: {
        LicenseTypeValue: "",
        FamilyTypeValue: "",
        RWAGroupValue: "",
        IDMGroupValue: "",
        },
        OrgData: {
            RWAMappingID:0,
            LicenseTypeID: 0,
            ProductFamilyID : 0,
            RWAGroupID: 0,
            IDMGroupID: 0,
            RWAName: "",
            IDMName: "",
            RWADescription: "",
            IDMDescription: "",
            NGCLicenseTypeName:"",
            ImportProductBom:false
        },
      IsOrgLock: false,
      Filter: [],
      LicenseTypes: [],
      FamilyTypes: [],
      RWAGroups: [],
      IDMGroups: [],
      IsLoaded: false,
      IsFiltering: false,
      isDialogOpen: false,
      isEditDialogOpen: false,
      dataFilled: false,
      orgLTypes: [],
      orgPFamilies: []
    };
  }

  componentDidMount() {
     this.getLookups();
     this.getInitial();
  }

  componentDidUpdate() {
      if (this.state.OrgData.RWAGroupID !== 0 && this.state.OrgData.LicenseTypeID !== 0 && this.state.OrgData.ProductFamilyID !== 0 && this.state.OrgData.IDMGroupID !== 0) {
          if (this.state.dataFilled === false) {
              this.setState({ dataFilled: true });
          }
      }
      else {
          if (this.state.dataFilled === true) {
              this.setState({ dataFilled: false });
          }
      }
  }

  handleRWAGroupChange = e => {
      const val = e.target.value;
      this.state.LicenseTypes = this.state.orgLTypes;
      this.state.FamilyTypes = this.state.orgPFamilies;

      //this.setState({ LicenseTypes: this.state.orgLTypes});
      //this.setState({ FamilyTypes: this.state.orgPFamilies);

      let orgData = Object.assign({}, this.state.OrgData);
      orgData.RWAGroupID = parseInt(val);
      this.setState({ OrgData: orgData });

      if (val === "1") {
          //Keep LT = Commercial, PF = Software
          this.setState({ LicenseTypes: this.state.LicenseTypes.filter(x => x.licenseCategoryName !== "EVAL")});
          this.setState({ FamilyTypes: this.state.FamilyTypes.filter(x => x.productFamilyCategoryName !== "Support with Container") });
      }
      if (val === "2") {
          //Keep  LT = Commercial,PF = Support with Container
          this.setState({ LicenseTypes: this.state.LicenseTypes.filter(x => x.licenseCategoryName !== "EVAL") });
          this.setState({ FamilyTypes: this.state.FamilyTypes.filter(x => x.productFamilyCategoryName !== "Software") });
      }
      else if (val === "3") {
          //Keep LT = Eval, PF = Software
          this.setState({ LicenseTypes: this.state.LicenseTypes.filter(x => x.licenseCategoryName !== "COMMERCIAL") });
          this.setState({ FamilyTypes: this.state.FamilyTypes.filter(x => x.productFamilyCategoryName !== "Support with Container") });
      }
  }

  handleLicenseTypeChange = e => {
    const val = e.target.value;
    let orgData = Object.assign({}, this.state.OrgData);
    orgData.LicenseTypeID = parseInt(val);
    this.setState({ OrgData: orgData });
   }

  handleProductFamilyChange = e => {
    const val = e.target.value;
    let orgData = Object.assign({}, this.state.OrgData);
    orgData.ProductFamilyID = parseInt(val);
    this.setState({ OrgData: orgData });
   }

   handleNGCLicenseTypeNameChange = e => {
    const val = e.target.value;
    let orgData = Object.assign({}, this.state.OrgData);
    orgData.NGCLicenseTypeName = val;
    this.setState({ OrgData: orgData});
    this.focusName();
};
handleBOMUpdateToggleChange = e => {
debugger;
  const val = !this.state.OrgData.ImportProductBom;
  let orgData = Object.assign({}, this.state.OrgData);
  orgData.ImportProductBom = val;
  this.setState({ OrgData: orgData });

};
focusName = e => {
  this.setState({ autofocusName: true, autofocusDescr: false });
}
  handleIDMGroupChange = e => {
    const val = e.target.value;
    let orgData = Object.assign({}, this.state.OrgData);
    orgData.IDMGroupID = parseInt(val);
    this.setState({ OrgData: orgData });
  }  

  openDialog(id) {
     this.setState({ isDialogOpen: true })
     this.setState({ rowID: id })
  }

  handleClose = () => this.setState({ isDialogOpen: false, isEditDialogOpen: false })

  render() {
    const {
      RWAMappings: data,
      LicenseTypes,
      FamilyTypes,
      RWAGroups,
      IDMGroups,
      IsLoaded
    } = this.state;

   const { canCreateRWAMapping, canViewRWAMapping } = this.props;

   const licTypes = LicenseTypes.map(o => {
        return (
            <option key={o.key} value={o.key}>
              {o.value}
            </option>
         );
    });
     const familyTypes = FamilyTypes.map(o => {
      return (
        <option key={o.key} value={o.key}>
          {o.value}
        </option>
      );
    });
    const rwaGroups = RWAGroups.map(o => {
      return (
        <option key={o.key} value={o.key}>
          {o.value}
        </option>
      );
      });
      const idmGroups = IDMGroups.map(o => {
          return (
              <option key={o.key} value={o.key}>
                  {o.value}
              </option>
          );
      }); 

    const columns = [
      {
        Header: "ID",
        accessor: "id",
        maxWidth: 50
      },
      {
            Header: "RWA UI Group ID",
            accessor: "rwaName",
            maxWidth: 130,
            Filter: ({ filter, onChange }) => (
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "All"}
                >
                    {rwaGroups}
                </select>
            ),
            filterMethod: (filter, row) => {
                if (parseInt(filter.value) === 0) {
                    return true;
                } else {
                    return row["_original"].rwaGroupId === parseInt(filter.value);
                }
            }
      },
      {
        Header: "Description",
        accessor: "rwaIdDescription",
        filterMethod: this.defaultFilter
      },
      {
        Header: "License Type",
        accessor: "licenseTypeName",
        maxWidth: 130,
          Filter: ({ filter, onChange }) => (
              <select
                  onChange={event => { onChange(event.target.value); }}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "All"}
              >
                  {licTypes}
          </select>
        ),
        filterMethod: (filter, row) => {
          if (parseInt(filter.value) === 0) {
            return row;
          } else {
            return row["_original"].licenseTypeId === parseInt(filter.value);
          }
        }
      },
      {
        Header: "Product Family",
        accessor: "productFamilyName",
        maxWidth: 130,
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
                value={filter ? filter.value : "All"}
            >
            {familyTypes}
          </select>
        ),
        filterMethod: (filter, row) => {
          if (parseInt(filter.value) === 0) {
            return true;
          } else {
            return row["_original"].productFamilyId === parseInt(filter.value);
          }
        }
      },
      
      {
            Header: "IDM Access ID",
            accessor: "idmName",
            maxWidth: 130,
            Filter: ({ filter, onChange }) => (
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "All"}
                >
                    {idmGroups}
                </select>
            ),
            filterMethod: (filter, row) => {
                console.log(filter.value);
                console.log(row);
                if (parseInt(filter.value) === 0) {
                    return true;
                } else {
                    return row["_original"].idmGroupId === parseInt(filter.value);
                }
            }
      },
      {
        Header: "NGC License Type Name",
        accessor: "ngcLicenseTypeName",
        filterMethod: this.defaultFilter
    },
      {
            Header: "Description",
            accessor: "idmIdDescription",
            filterMethod: this.defaultFilter
      },      
      {
        Header: "Edit",
        style: { flex: "1" },
        width: 100,
        minWidth: 100,
        maxWidth: 100,
        filterable: false,
        sortable: false,
        Cell: props => {
          return (
              <div>
                  {canCreateRWAMapping && (
                      <div>
                          <button className="btn small"
                              onClick={() => {
                                debugger;
                                  let selRow = props.row._original;
                                  this.handleClickEdit(selRow);
                              }} >
                              <FontAwesomeIcon icon="edit" color="Grey" />
                          </button>
                          <Modal isOpen={this.state.isEditDialogOpen} style={customStyles} overlayClassName="Overlay"
                          >
                              <div>
                                  <div className="modalDivHeader">
                                  </div>
                                  <div className="details-section-header modalLabel" >
                                      <label className="modLabel"> RWA Mapping Maintenance </label>
                                      <button id="btnClose" onClick={this.handleClose}>  X  </button>
                                  </div>
                                  <RWAMappingInfo
                                      orgData={this.state.OrgData}
                                      handleLicenseTypeChange={this.handleLicenseTypeChange}
                                      handleProductFamilyChange={this.handleProductFamilyChange}
                                      handleNGCLicenseTypeNameChange={this.handleNGCLicenseTypeNameChange}
                                      handleRWAGroupChange={this.handleRWAGroupChange}
                                      handleIDMGroupChange={this.handleIDMGroupChange}
                                      handleBOMUpdateToggleChange={this.handleBOMUpdateToggleChange}
                                      
                                      licTypes={this.state.LicenseTypes}
                                      familyTypes={this.state.FamilyTypes}
                                      rwaGroups={this.state.RWAGroups}
                                      idmGroups={this.state.IDMGroups}
                                      dataFilled={this.state.dataFilled}
                                      autoFocusName={this.state.autofocusName}
                                      importBomInfo={this.state.importBomInfo}
                                      onSaveClick={this.handleSaveClick} />
                              </div>
                          </Modal>
                      </div>
                  )}
              </div>
          );
        }
      },
      
      {
            Header: "Delete",
            style: { flex: "1" },
            width: 100,
            minWidth: 100,
            maxWidth: 100,
            filterable: false,
            sortable: false,
            Cell: props => {
                return (
                    <div>
                        {canCreateRWAMapping && (
                            <div>
                                <button className="btn small"
                                    onClick={() => { this.openDialog(props.row._original.id); }} >
                                    <FontAwesomeIcon icon="trash" color="Grey" />
                                </button>
                                <Modal isOpen={this.state.isDialogOpen} style={customStyles} overlayClassName="Overlay" >
                                    <div>
                                        <div className="modalDivHeader">
                                        </div>
                                        <div className="details-section-header modalLabel">
                                            <label className="modalLabel">  Delete RWA Mapping </label>
                                            <button id="btnClose" onClick={this.handleClose}>  X  </button>
                                        </div>
                                        <h4 className="lblNVGreen">Are you sure you want to delete record {this.state.rowID} ?</h4>
                                        <center>
                                            <button className="btn" onClick={() => this.handleClickDelete(this.state.rowID)}> Yes </button>
                                        </center>
                                    </div>
                                </Modal>
                            </div>
                        )}
                    </div>
                );
          }
        },
    ];

    if (!IsLoaded) {
      return (
        <div className="loading-full">
          <FontAwesomeIcon
            icon="spinner"
            spin
            className="color-nvgreen"
            size="2x"
          />
          <div className="loading-text">loading page content</div>
        </div>
      );
    }

    return (
        <div>
            {canViewRWAMapping && (
                <NavMenu />
            )}
            <div className="flex-fill">
                <div className="divAddBtn">
                    <button className="btn" id="btnAdd" onClick={() => { this.handleClickEdit() }} >
                        <FontAwesomeIcon icon="plus" />
                    </button>
                </div>
            </div>
            <div className="content" >
                <h4 className="lblNVGreen"> RWA Mappings </h4>
                {canViewRWAMapping && (
                    <div>
                        <ReactTable
                            data={data}
                            columns={columns}
                            defaultPageSize={this.state.Take}
                            minRows={0}
                            filterable={this.state.ShowFilters}
                            resizable={false}
                            className="-striped -highlight"
                            filtered={this.state.Filter}
                            onFilteredChange={filtered => {
                                this.setState({ Filter: filtered });
                            }}
                        >
                            {(state, makeTable, instance) => {
                                let recordsInfoText = "";

                                const { filtered, pageRows, pageSize, sortedData, page } = state;

                                if (sortedData && sortedData.length > 0) {
                                    let isFiltered = filtered.length > 0;

                                    let totalRecords = sortedData.length;

                                    let recordsCountFrom = page * pageSize + 1;

                                    let recordsCountTo = recordsCountFrom + pageRows.length - 1;

                                    if (isFiltered)
                                        recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
                                    else
                                        recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
                                } else recordsInfoText = "No records";

                                const dashboard = (
                                    <div className="dashboard">
                                        <div className="dashboard-text">{recordsInfoText}</div>
                                    </div>
                                );
                                return (
                                    <div>
                                        {dashboard}
                                        {makeTable()}
                                    </div>
                                );
                            }}
                        </ReactTable>
                        <div id="divTbl"> </div>
                    </div>
                )}
            </div>
        </div>
     );
    }

  handleSaveClick = e => {
        let orgData = Object.assign({}, this.state.OrgData);
        let rwaMappingID = parseInt(orgData.RWAMappingID) ? parseInt(orgData.RWAMappingID) : 0;

        let data = {
            Id: rwaMappingID,
            LicenseTypeID: parseInt(orgData.LicenseTypeID),
            ProductFamilyID: parseInt(orgData.ProductFamilyID),
            RWAGroupID: parseInt(orgData.RWAGroupID),
            IDMGroupID: parseInt(orgData.IDMGroupID),
            NGCLicenseTypeName:orgData.NGCLicenseTypeName,
            ImportProductBom:orgData.ImportProductBom
        };

        let url = "";
        if (rwaMappingID === 0) {
            url = api.POST_CREATE_RWA_MAPPINGS;
        }
        else {
            url = api.POST_UPDATE_RWA_MAPPINGS + "/" + rwaMappingID;
        }

        console.log(data);
        adalApiFetch(
            axios,
            url,
            {
                method: "post",
                data: data,
                //cancelToken: this.source.token
            }
        )
        .then(response => {
            console.log(response);
            this.getInitial();
            this.resetElements();
            toastSuccess(response.data["Data"]);
        })
        .catch(error => {
            console.log(error);
            if (error.response) {
                if (error.response.data.title)
                    toastError(JSON.stringify(error.response.data["errors"]));
                else
                    toastError(error.response.data["Message"]);
            }
        });
    }

  resetElements = e => {
        let orgData = Object.assign({}, this.state.OrgData);
        orgData.RWAMappingID = 0;
        orgData.LicenseTypeID = 0;
        orgData.ProductFamilyID = 0;
        orgData.RWAGroupID = 0;
        orgData.IDMGroupID = 0;
        this.setState({ OrgData: orgData, isDialogOpen: false, isEditDialogOpen: false }, function () { });
    }
 
  handleClickEdit(selRow) {
        let orgData = Object.assign({}, this.state.OrgData);
        orgData.RWAMappingID = selRow ? selRow.id : 0;
        orgData.LicenseTypeID = selRow ?  selRow.licenseTypeId:0;
        orgData.ProductFamilyID = selRow ? selRow.productFamilyId:0;
        orgData.RWAGroupID = selRow ? selRow.rwaGroupId:0;
        orgData.IDMGroupID = selRow ? selRow.idmGroupId : 0;
        orgData.NGCLicenseTypeName = selRow ? selRow.ngcLicenseTypeName : '';
        this.setState({ OrgData: orgData, isEditDialogOpen: true}, function () { });
    }

  handleClickDelete(props) {
        adalApiFetch(
            axios,
            api.POST_DELETE_RWA_MAPPINGS + '/' + parseInt(props),
            {
                method: "post",
                //cancelToken: this.source.token
            }
        )
        .then(res => {
            console.log(res.data);
            this.handleClose();
            this.getInitial();
            toastSuccess(res.data["Data"]);
         })
        .catch(error => {
            console.log(error);
            if (error.response) {
                console.log(error.response);
                toastError(error.response.data["Message"]);
            }
        });
    }

  defaultFilter(filter, row, column) {
      return (
        row[filter.id] &&
        row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
        );
    }

  buildFilter() {
    let filterString = "";
     
    if (this.state.Filters.LicenseTypeValue !== "") {
      if (filterString !== "") {
        filterString += "&&";
      }
      filterString += this.state.Filters.LicenseTypeValue;
    }
    if (this.state.Filters.FamilyTypeValue !== "") {
      if (filterString !== "") {
        filterString += "&&";
      }
      filterString += this.state.Filters.FamilyTypeValue;
    }
      if (this.state.Filters.RWAGroupValue !== "") {
          if (filterString !== "") {
              filterString += "&&";
          }
          filterString += this.state.Filters.RWAGroupValue;
      }
      if (this.state.Filters.IDMGroupValue !== "") {
          if (filterString !== "") {
              filterString += "&&";
          }
          filterString += this.state.Filters.IDMGroupValue;
      }  

    return filterString;
  }

  getInitial(useFilter) {
    const filterString = this.buildFilter();
    let options = {
      params: {
        //take: this.state.Take,
        filter: filterString
      }
    };

    if (!useFilter) {
      delete options.params;
    }
      adalApiFetch(axios, api.GET_RWA_MAPPINGS, options) 
      .then(response => {
        const modifiedData = response.data.records.map(r => {
          return r;
        });
debugger;
        this.setState({
          IsLoaded: true,
          RWAMappings: modifiedData,
          Count: response.data.totalCount,
          DisplayedCount: this.state.Take,
          GetMoreEnabled: true,
        });
      })
      .catch(error => {
         console.log(error.response);
      });
  }

  getLookups() {
      adalApiFetch(axios, api.GET_RWA_LOOKUPS, {})
        .then(response => {
            response.data.LicenseType.unshift(this.state.defaultOption);
            response.data.ProductFamily.unshift(this.state.defaultOption);
            response.data.RWAGroup.unshift(this.state.defaultOption);
            response.data.IDMGroup.unshift(this.state.defaultOption);


             this.setState({
              LicenseTypes: response.data.LicenseType,
              FamilyTypes: response.data.ProductFamily,
              RWAGroups: response.data.RWAGroup,
              IDMGroups: response.data.IDMGroup,
              orgLTypes: response.data.LicenseType,
              orgPFamilies: response.data.ProductFamily,
            });
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          console.log(error.response);
        }
      });
  }
}

export default RWAMappingList;

