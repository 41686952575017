import PropTypes from "prop-types";
import React, {Component} from "react";

export class Toggle extends Component{
    constructor(props){
        super(props);
    }
    render(){
        const{selected,toggleSelected}=this.props;
        return (
            <div className="toggle-container" onClick={toggleSelected}>
               
                <div className={`dialog-button ${selected ? "" :"disabled"}`}>
                    {selected?"Yes":"No"}
                </div>
            </div>
        );
    }
}
Toggle.propTypes={
    selected:PropTypes.bool.isRequired,
    toggleSelected:PropTypes.func.isRequired
}