import React from "react";
import RegistrationDetail from "../../RegistrationDetail"
import * as accessTypes from "../../constants/accessTypes";
import Popup from "reactjs-popup";

export default class PCustDetailsPopUp extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
          open: false, isLoaded: false
      };
      this.openModal = this.openModal.bind(this);
      this.closeModal = this.closeModal.bind(this);
  }
  openModal() {
    this.setState({ open: true });
  }
  closeModal() {
      this.setState({ open: false, isLoaded: false });
      this.props.refreshParent([{ id: "requestStatusName", value: "2" }]);
    }

  render() {
      return (
          <div className='auditTrailSty'>
              <button
                  className='btn small auditBtn'
                  onClick={this.openModal}
              >
                  {this.props.showMatchState ? "Match" : "View"}
              </button>
              <Popup
                  className="pcustpopup"
                  open={this.state.open}
                  closeOnDocumentClick
                  onClose={this.closeModal}
              >
                  <div className='modal'>
                      <a className='close' onClick={this.closeModal}>
                          &times;
                      </a>
                      <RegistrationDetail
                          {...this.props}
                          canProcessReg={this.props.canProcessReg}
                          canViewCustAssign={this.props.canViewCustAssign}
                          canCreateCust={this.props.canCreateCust}
                          canAssignCust={this.props.canAssignCust}
                          viewCancelStatus={this.props.viewCancelStatus}
                          canCreateRWAMapping={this.props.canCreateRWAMapping}
                          ispopUp={true}
                          refreshParentPop={this.closeModal}
                      />
                  </div>
              </Popup>
          </div>
    );
  }
}
