import React from "react";
import Popup from "reactjs-popup";
import "../../styles/Collapsible.scss";
import { adalApiFetch } from "../../adalConfig";
import axios from "axios";
import * as api from "../../constants/api";
import formatDate from "../../util/Date";
import { toastError } from "../../util/CommonFunctions";
import { toastSuccess } from "../../util/CommonFunctions";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Button } from "@material-ui/core";
import {
  fade,
  ThemeProvider,
  withStyles,
  makeStyles,
  createMuiTheme,
} from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";

import { green } from "@material-ui/core/colors";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#76b900",
    },
  },
});
export default class CancelReg extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isChecked: false,
      helperText: "",
      error: false,
      textValue: "",
      isValid: false,
      cancelbtnText: "Cancel Registration",
      isCancelbtnDisabled: false,
      isRequestCancelled: false,
    };
    this.openModal = this.openModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.setCancelStatus = this.setCancelStatus.bind(this);
    this.onChangeText = this.onChangeText.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.CancelbtnClick = this.CancelbtnClick.bind(this);
  }

  openModal() {
    this.setState({ open: true });
  }
  closeModal() {
    this.setState({
      open: false,
      textValue: "",
      helperText: "",
      error: false,
      isValid: false,
    });
  }
  CancelbtnClick() {
    this.setState({
      open: false,
      textValue: "",
      helperText: "",
      error: false,
      isValid: false,
    });
  }
  handleChange(event) {
    this.setState({ isChecked: event.target.checked });
  }
  onChangeText(event) {
    if (event.target.value.length > 0) {
      this.setState({
        textValue: event.target.value,
        helperText: "",
        error: false,
        isValid: true,
      });
    } else {
      this.setState({ textValue: "", isValid: false });
    }
  }

  setCancelStatus() {
    if (this.state.textValue) {
      this.setState({ helperText: "", error: false, isValid: true });
    } else {
      this.setState({
        helperText: "Should not be Empty",
        error: true,
        isValid: false,
      });
    }
    let data = {};

    data = {
      RegID: this.props.regID,
      CancellationReason: this.state.textValue,
    };

    if (this.state.isValid) {
      adalApiFetch(axios, api.POST_CANCEL_REGISTRATION, {
        method: "post",
        data: data,
      })
        .then((response) => {
          console.log(response);
          this.setState({
            cancelbtnText: "Registration Cancelled",
            isCancelbtnDisabled: true,
            isRequestCancelled: true,
          });
          //this.setState({ Matches: response.data, MatchesLoaded: true });
          toastSuccess("Status Set to Cancel Successfully");
          this.closeModal();
        })
        .catch((error) => {
          console.log(error);
          if (error.response) {
            console.log(error.response);
            toastError(error.response.data["Message"]);
          }
        });
    }
  }
  render() {
    const isCancelled =
      this.props.statusName === "CANCEL" || this.state.isRequestCancelled;

    if (
      !this.props.viewCancelStatus ||
      this.props.statusName === undefined ||
      this.props.statusName === "COMPL"
    ) {
      return <div></div>;
    }

    if (isCancelled) {
      return <RegistrationCancelled></RegistrationCancelled>;
    }
    return (
      <div className='auditTrailSty'>
        <Button
          variant='contained'
          onClick={this.openModal}
          size='small'
          style={{ "margin-left": "1em" }}
          className={"auditBtn cancelRedStyles"}
        >
          Cancel Registration
        </Button>
        <Popup
          open={this.state.open}
          closeOnDocumentClick
          onClose={this.closeModal}
        >
          <div className='modal'>
            <a className='close' onClick={this.closeModal}>
              &times;
            </a>
            <div>
              <Grid
                container
                direction='row'
                justify='center'
                alignItems='center'
                spacing={3}
              >
                <Grid justify='center' item xs={2}></Grid>
                <Grid justify='center' item xs={8}>
                  {" "}
                  <ThemeProvider theme={theme}>
                    <TextField
                      multiline
                      label='Reason For Cancellation'
                      variant='outlined'
                      id='mui-theme-provider-outlined-input'
                      inputProps={{ maxLength: 100 }}
                      helperText={this.state.helperText}
                      onChange={this.onChangeText}
                      error={this.state.error}
                    />
                  </ThemeProvider>
                </Grid>
                <Grid justify='center' item xs={2}></Grid>

                <Grid justify='center' item xs={12}>
                  {/* <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.isChecked}
                      onChange={this.handleChange}
                      name='checkedA'
                    />
                  }
                  label='Are you sure, you want to cancel the registration.'
                /> */}
                </Grid>

                <Grid justify='center' item xs={7}></Grid>
                <Grid justify='right' item xs={1}>
                  {" "}
                  <Button
                    variant='contained'
                    onClick={this.setCancelStatus}
                    style={{
                      backgroundColor: "#76b900",
                      color: "white",
                    }}
                  >
                    Save
                  </Button>
                  {/* <Button variant='contained'>Cancel</Button> */}
                </Grid>
                <Grid justify='' item xs={3}>
                  {" "}
                  <Button
                    variant='contained'
                    onClick={this.CancelbtnClick}
                    style={{ "margin-left": "1em" }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid justify='right' item xs={1}>
                  {" "}
                </Grid>
              </Grid>
            </div>
          </div>
        </Popup>
      </div>
    );
  }
}

function RegistrationCancelled(props) {
  return (
    <div>
      <Button
        variant='contained'
        disabled={true}
        size='small'
        style={{ "margin-left": "1em" }}
        className={"auditBtn"}
      >
        Registration Cancelled
      </Button>
    </div>
  );
}
