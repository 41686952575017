import React from "react";
import { PropTypes } from "prop-types";

const Select = props => {
  let value = props.valueFieldName ? props.valueFieldName : "key";
  let display = props.displayFieldName ? props.displayFieldName : "value";

  let optionsArray = props.optionValues.map(optionItem => {
    return (
      <option key={optionItem[value]} value={optionItem[value]}>
        {optionItem[display]}
      </option>
    );
  });

  return (
      <div className={"selectDiv"}>
          <label className={props.className} style={props.style}>
           {props.labelText}:{" "}
        </label>
        <select
          id={props.id}
          required={props.isRequired}
          className={props.inputClassName}
          style={props.innerStyle}
          value={props.value}
          onChange={props.onChange}
          disabled={props.readOnly}
          readOnly={props.readOnly}
         >
          {optionsArray}
        </select>
    </div>
  );
};

Select.propTypes = {
  optionValues: PropTypes.array.isRequired,
  isRequired: PropTypes.bool,
  labelText: PropTypes.string.isRequired,
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  displayFieldName: PropTypes.string,
  valueFieldName: PropTypes.string
};

Select.defaultProps = {
  readOnly: false
};

export default Select;
