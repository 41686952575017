import React, { Component } from "react";
import axios from "axios";
import * as api from "../../constants/api";
import { adalApiFetch } from "../../adalConfig";
import ReactTable from "react-table";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "react-table/react-table.css";
import LicenseTypeInfo from "../licensetype/LicenseTypeInfo";
import Modal from 'react-modal';
import { toastSuccess, toastError, customStyles } from '../../util/CommonFunctions'
import NavMenu from '../NavMenu'

Modal.setAppElement('#root')

class LicenseTypeList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            defaultOption: { key: 0, value: "All" },
            LicenseTypes: [],
            LicenseTypeCategories : [],
            DisplayedCount: 0,
            Count: 0,
            Take: 10,
            ShowFilters: true,
            Filters: {
            },
            OrgData: {
                LicTypeId: 0,
                LicTypeName: "",
                LicTypeCategoryID: 0,                
            },
            IsOrgLock: false,
            Filter: [],
            IsLoaded: false,
            IsFiltering: false,
            isDialogOpen: false,
            isEditDialogOpen: false,
            autofocusName: true,
            autofocusDescr: false,
            dataFilled: false
        };
    }    
    


    componentDidMount() {
        this.getLookups();
        this.getInitial();
    }

    componentDidUpdate() {
        if (this.state.OrgData.LicTypeName !== "" && this.state.OrgData.LicTypeCategoryID !== 0) {
            if (this.state.dataFilled === false) {
                this.setState({ dataFilled: true });
            }
        }
        else {
            if (this.state.dataFilled === true) {
                this.setState({ dataFilled: false });
            }
        }
    }

    focusName = e => {
        this.setState({ autofocusName: true, autofocusDescr: false });
    }
   

    handleLicenseTypeName = e => {
        const val = e.target.value;
        let orgData = Object.assign({}, this.state.OrgData);
        orgData.LicTypeName = val;
        this.setState({ OrgData: orgData});
        this.focusName();
    };

    handleLicenseTypeCategoryChange = e => {
        const val = e.target.value;
        let orgData = Object.assign({}, this.state.OrgData);
        orgData.LicTypeCategoryID = parseInt(val);
        this.setState({ OrgData: orgData });
    } 
 
    openDialog(id) {
        this.setState({ isDialogOpen: true })
        this.setState({ rowID: id })
    }

    handleClose = () => this.setState({ isDialogOpen: false, isEditDialogOpen: false })


    render() {
        const {
            LicenseTypes: data,
            IsLoaded
        } = this.state;

        const { canCreateRWAMapping, canViewRWAMapping } = this.props;

        const columns = [
            {
                Header: "ID",
                accessor: "id",
                maxWidth: 50
            },
            {
                Header: "Name",
                accessor: "typeName",
                filterMethod: this.defaultFilter
            },
            {
                Header: "Category",
                accessor: "licenseCategoryName",
                filterMethod: this.defaultFilter
            },
            {
                Header: "Edit",
                style: { flex: "1" },
                width: 100,
                minWidth: 100,
                maxWidth: 100,
                filterable: false,
                sortable: false,
                Cell: props => {
                    return (
                        <div>
                            {canCreateRWAMapping && (
                                <div>
                                    <button className="btn small"
                                    onClick={() => {
                                        let selRow = props.row._original;
                                        this.handleClickEdit(selRow);
                                    }} >
                                    <FontAwesomeIcon icon="edit" color="Grey" />
                                </button>
                                    <Modal isOpen={this.state.isEditDialogOpen} style={customStyles} overlayClassName="Overlay"
                                >
                                    <div>
                                        <div className="modalDivHeader">
                                        </div>
                                        <div className="details-section-header modalLabel">
                                            <label className="modLabel"> License Type Maintenance </label>
                                            <button id="btnClose" onClick={this.handleClose}>  X  </button>
                                        </div>
                                        <LicenseTypeInfo
                                            orgData={this.state.OrgData}
                                            licTypeCategories={this.state.LicenseTypeCategories}
                                            handleLicenseTypeName={this.handleLicenseTypeName}
                                            handleLicenseTypeCategoryChange={this.handleLicenseTypeCategoryChange}
                                            autoFocusName={this.state.autofocusName}
                                            dataFilled={this.state.dataFilled}
                                            onSaveClick={this.handleSaveClick} />
                                    </div>
                                    </Modal>
                                </div>
                            )}
                        </div>
                    );
                }
            },
            {
                Header: "Delete",
                style: { flex: "1" },
                width: 100,
                minWidth: 100,
                maxWidth: 100,
                filterable: false,
                sortable: false,
                Cell: props => {
                    return (
                        <div>
                            {canCreateRWAMapping && (
                                <div>
                                    <button className="btn small"
                                        onClick={() => { this.openDialog(props.row._original.id); }} >
                                        <FontAwesomeIcon icon="trash" color="Grey" />
                                    </button>
                                    <Modal isOpen={this.state.isDialogOpen} style={customStyles} overlayClassName="Overlay" >
                                        <div>
                                            <div className="modalDivHeader">
                                            </div>
                                            <div className="details-section-header modalLabel">
                                                <label className="modalLabel">  Delete License Type </label>
                                                <button id="btnClose" onClick={this.handleClose}>  X  </button>
                                            </div>
                                            <h4 className="lblNVGreen">Are you sure you want to delete record {this.state.rowID} ?</h4>
                                            <center>
                                                <button className="btn" onClick={() => this.handleClickDelete(this.state.rowID)}> Yes </button>
                                            </center>
                                        </div>
                                    </Modal>
                                </div>
                            )}
                        </div>
                    );
                }
            },
        ];

        if (!IsLoaded) {
            return (
                <div className="loading-full">
                    <FontAwesomeIcon
                        icon="spinner"
                        spin
                        className="color-nvgreen"
                        size="2x"
                    />
                    <div className="loading-text">loading page content</div>
                </div>
            );
        }

        return (
            <div>
                {canViewRWAMapping && (
                    <NavMenu />
                )}
                {canCreateRWAMapping && (
                    <div className="flex-fill">
                        <div className="divAddBtn">
                            <button className="btn" id="btnAdd" onClick={() => { this.handleClickEdit() }} >
                                <FontAwesomeIcon icon="plus" />
                            </button>
                        </div>
                    </div>
                )}
                <div className="content" >
                    <h4 className="lblNVGreen"> License Types </h4>

                    {canViewRWAMapping && (
                        <div>
                            <ReactTable
                            data={data}
                            columns={columns}
                            defaultPageSize={this.state.Take}
                            minRows={0}
                            filterable={this.state.ShowFilters}
                            resizable={false}
                            className="-striped -highlight"
                            filtered={this.state.Filter}
                            onFilteredChange={filtered => {
                                this.setState({ Filter: filtered });
                            }}
                        >
                            {(state, makeTable, instance) => {
                                let recordsInfoText = "";

                                const { filtered, pageRows, pageSize, sortedData, page } = state;

                                if (sortedData && sortedData.length > 0) {
                                    let isFiltered = filtered.length > 0;
                                    let totalRecords = sortedData.length;
                                    let recordsCountFrom = page * pageSize + 1;
                                    let recordsCountTo = recordsCountFrom + pageRows.length - 1;

                                    if (isFiltered)
                                        recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
                                    else
                                        recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
                                } else recordsInfoText = "No records";

                                const dashboard = (
                                    <div className="dashboard">
                                        <div className="dashboard-text">{recordsInfoText}</div>
                                    </div>
                                );
                                return (
                                    <div>
                                        {dashboard}
                                        {makeTable()}
                                    </div>
                                );
                            }}
                        </ReactTable>
                            <div id="divTbl"> </div>
                        </div>
                    )}
                 </div>

            </div>
        );
    }
    handleSaveClick = e => {
        let orgData = Object.assign({}, this.state.OrgData);
        let licTypeID = parseInt(orgData.LicTypeId) ? parseInt(orgData.LicTypeId) : 0;

        let data = {
            Id: licTypeID,
            TypeName: orgData.LicTypeName,
            LicenseCategoryID: orgData.LicTypeCategoryID
        };

        if (data.TypeName === "" ) {
            toastError("License Type Name Required");
            return false;
        }

        if (data.LicenseCategoryID === 0) {
            toastError("License Category Required");
            return false;
        }

        let url = "";
        if (licTypeID === 0) {
            url = api.POST_CREATE_LICENSE_TYPES;
        }
        else {
            url = api.POST_UPDATE_LICENSE_TYPES + "/" + licTypeID;
        }
        console.log(data);
        adalApiFetch(
            axios,
            url,
            {
                method: "post",
                data: data,
                //cancelToken: this.source.token
            }
        )
        .then(response => {
            console.log(response);
            this.getInitial();
            this.resetElements();
            toastSuccess(response.data["Data"]);
      })
        .catch(error => {
            console.log(error);
            if (error.response) {
                if (error.response.data.title)
                    toastError(JSON.stringify(error.response.data["errors"]));
                else
                    toastError(error.response.data["Message"]);
            }
        });
    }

    resetElements = e => {
        let orgData = Object.assign({}, this.state.OrgData);
        orgData.LicTypeId = 0;
        orgData.LicTypeName = "";
        orgData.LicTypeCategoryID = 0;
        this.setState({ OrgData: orgData, isDialogOpen: false, isEditDialogOpen: false }, function () { });
    }

    handleClickEdit(selRow) {
        console.log(selRow);
        this.setState({ isEditDialogOpen: true })
        let orgData = Object.assign({}, this.state.OrgData);
        orgData.LicTypeId = selRow ? selRow.id : 0;
        orgData.LicTypeName = selRow ? selRow.typeName : "";
        orgData.LicTypeCategoryID = selRow ? selRow.licenseCategoryID : 0;
        this.setState({ OrgData: orgData, isEditDialogOpen: true }, function () {
            console.log(this.state.OrgData);
        });
        this.focusName();
    }

    handleClickDelete(props) {
        adalApiFetch(
            axios,
            api.POST_DELETE_LICENSE_TYPES + '/' + parseInt(props),
            {
                method: "post",
                //cancelToken: this.source.token
            }
        )
        .then(res => {
            console.log(res.data);
            this.handleClose();
            this.getInitial();
            toastSuccess(res.data["Data"]);
       })
        .catch(error => {
            console.log(error);
            if (error.response) {
                console.log(error.response);
                toastError(error.response.data["Message"]);
            }
        });
    }

    defaultFilter(filter, row, column) {
        return (
            row[filter.id] &&
            row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
        );
    }

    buildFilter() {
        let filterString = "";
        return filterString;
    }

    getInitial(useFilter) {
        const filterString = this.buildFilter();
        let options = {
            params: {
                //take: this.state.Take,
                filter: filterString
            }
        };

        if (!useFilter) {
            delete options.params;
        }
        adalApiFetch(axios, api.GET_LICENSE_TYPES, options)
            .then(response => {
                const modifiedData = response.data.records.map(r => {
                    return r;
                });

                this.setState({
                    IsLoaded: true,
                    LicenseTypes: modifiedData,
                    Count: response.data.totalCount,
                    DisplayedCount: this.state.Take,
                    GetMoreEnabled: true
                });
            })
            .catch(error => {
                console.log(error.response);
            });
    }

    getLookups() {
        adalApiFetch(axios, api.GET_RWA_LOOKUPS, {})
            .then(response => {
                response.data.LicenseTypeCategory.unshift(this.state.defaultOption);
                this.setState({
                    LicenseTypeCategories: response.data.LicenseTypeCategory,
 
                });
            })
            .catch(error => {
                console.log(error);
                if (error.response) {
                    console.log(error.response);
                }
            });
    }
}

export default LicenseTypeList;

