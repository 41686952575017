let baseUrl = "#{Licensing.Admin.Api.Url}";

function getBase() {
  
 // if (baseUrl.indexOf("Licensing.Admin.Api.Url") > -1) {
    baseUrl = "https://localhost:44365/";
   baseUrl ="https://epradmin-stg.nvidia.com/";
   //baseUrl ="https://epradmin.nvidia.com/";
   baseUrl ="https://epradminelevate-stg.nvidia.com/";
  //}

  return baseUrl;
}

export const GET_REGISTRATIONS = getBase() + "api/admin/";
export const GET_REGISTRATIONSQUICKSEARCH = getBase() + "api/admin/quicksearch";

export const GET_REPORTDATA = getBase() + "api/admin/report";
export const GET_COUNTRIES = getBase() + "api/admin/countries/";
export const GET_STATE_FROM_COUNTRY =
  getBase() + "api/admin/statesbycountrycode/";
export const GET_LOOKUPS = getBase() + "api/admin/filters/";
export const GET_MATCHES = getBase() + "api/admin/matchcustomers/";
export const GET_STATUS = getBase() + "api/admin/getorchestrationstatus/";
export const RE_RUN_ORCHESTRATION = getBase() + "api/admin/runorchestration/";
export const GET_AUTHORIZATION = getBase() + "api/admin/getAccessAreas";
export const POST_CREATE_CUSTOMER = getBase() + "api/admin/createaccount";
export const POST_ASSIGN_CUSTOMER = getBase() + "api/admin/assign";
export const POST_CANCEL_REGISTRATION =
  getBase() + "api/admin/CancelRegistration";

export const POST_UPDATE_PECID = getBase() + "api/admin/UpdatePEC";
export const POST_UPDATE_USERNAME = getBase() + "api/pcust/PostUpdateUsername";

export const GET_RWA_LOOKUPS = getBase() + "api/rwamapping/filters/";
export const GET_RWA_GROUPS = getBase() + "api/rwamapping/rwagroup/";
export const GET_IDM_GROUPS = getBase() + "api/rwamapping/idmgroup/";
export const GET_RWA_MAPPINGS = getBase() + "api/rwamapping/";
export const GET_LICENSE_TYPES = getBase() + "api/rwamapping/licensetype";
export const GET_PRODUCT_FAMILIES = getBase() + "api/rwamapping/productfamily";

export const POST_CREATE_RWA_GROUPS =
  getBase() + "api/rwamapping/createrwagroup/";
export const POST_CREATE_IDM_GROUPS =
  getBase() + "api/rwamapping/createidmgroup/";
export const POST_CREATE_RWA_MAPPINGS =
  getBase() + "api/rwamapping/createrwamapping/";
export const POST_CREATE_LICENSE_TYPES =
  getBase() + "api/rwamapping/createlicensetype/";
export const POST_CREATE_PRODUCT_FAMILIES =
  getBase() + "api/rwamapping/createproductfamily";

export const POST_UPDATE_RWA_GROUPS =
  getBase() + "api/rwamapping/updaterwagroup";
export const POST_UPDATE_IDM_GROUPS =
  getBase() + "api/rwamapping/updateidmgroup";
export const POST_UPDATE_RWA_MAPPINGS =
  getBase() + "api/rwamapping/updaterwamapping";
export const POST_UPDATE_LICENSE_TYPES =
  getBase() + "api/rwamapping/updatelicensetype";
export const POST_UPDATE_PRODUCT_FAMILIES =
  getBase() + "api/rwamapping/updateproductfamily";

export const POST_DELETE_RWA_GROUPS =
  getBase() + "api/rwamapping/deleterwagroup";
export const POST_DELETE_IDM_GROUPS =
  getBase() + "api/rwamapping/deleteidmgroup";
export const POST_DELETE_RWA_MAPPINGS =
  getBase() + "api/rwamapping/deleterwamapping";
export const POST_DELETE_LICENSE_TYPES =
  getBase() + "api/rwamapping/deletelicensetype";
export const POST_DELETE_PRODUCT_FAMILIES =
  getBase() + "api/rwamapping/deleteproductfamily";
export const GET_AUDITTRAIL = getBase() + "api/admin/getAuditTrail";
